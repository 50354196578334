import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import { getDatabase } from "firebase/database";
import { DBUser, Space } from "./types/Models";
import { ref, set, serverTimestamp as databaseServerTimestamp, onDisconnect, onValue } from 'firebase/database'
import {
  doc, setDoc, getDoc, updateDoc, deleteDoc, Timestamp, collection, query, onSnapshot,
  serverTimestamp as fsServerTimestamp, DocumentChangeType
} from "firebase/firestore";


// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const dev_firebaseConfig = {
  apiKey: "AIzaSyCoc9XSkd40Do2fZ1mav30GkHgaP4XYE_4",
  authDomain: "bubble-workspace-dev.firebaseapp.com",
  databaseURL: "https://bubble-workspace-dev-default-rtdb.firebaseio.com",
  projectId: "bubble-workspace-dev",
  storageBucket: "bubble-workspace-dev.appspot.com",
  messagingSenderId: "991714515344",
  appId: "1:991714515344:web:259cf28307d2ae966e7b15",
  measurementId: "G-MX3XRNDW1W"
};

const prod_firebaseConfig = {
  apiKey: "AIzaSyCOhNe2R_c3LW9On3tSc1UKBYigAo04MhM",
  authDomain: "bubble-workspace.firebaseapp.com",
  databaseURL: "https://bubble-workspace-default-rtdb.firebaseio.com",
  projectId: "bubble-workspace",
  storageBucket: "bubble-workspace.appspot.com",
  messagingSenderId: "90385855790",
  appId: "1:90385855790:web:5cb0a6493c8f703f9d76bf",
  measurementId: "G-XPEPLRXZP1"
}

const isDev = window.location.origin.toLowerCase().indexOf("time.bubbleworkspace.com") === -1

// Initialize Firebase
const app = initializeApp(isDev ? dev_firebaseConfig : prod_firebaseConfig);
const auth = getAuth(app);
const fs = getFirestore(app)
const database = getDatabase(app);

// Confirm the link is a sign-in with email link.
if (isSignInWithEmailLink(auth, window.location.href)) {
  // Additional state parameters can also be passed via URL.
  // This can be used to continue the user's intended action before triggering
  // the sign-in operation.
  // Get the email if available. This should be available if the user completes
  // the flow on the same device where they started it.
  let email = window.localStorage.getItem('emailForSignIn');
  if (!email) {
    // User opened the link on a different device. To prevent session fixation
    // attacks, ask the user to provide the associated email again. For example:
    email = window.prompt('Please provide your email for confirmation');
  }

  if (email) {
    // The client SDK will parse the code from the link for you.
    signInWithEmailLink(auth, email, window.location.href)
      .then((result) => {
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn');
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
      })
      .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
      });
  }
}

const parseEmail = (email: string) => {
  const parts = email.toLowerCase().split('@')
  if (parts.length !== 2)
    throw new Error(`Invalid email address '${email}'.`);

  return {
    spaceId: parts[1],
    userId: parts[0]
  }

}

export const NEW_GROUP_NAME = 'Untitled Group'
export const NEW_GROUP_ID_PREFIX = 'k5fhh3k'

const fb = {
  auth,
  db: {
    space: {
      get: async (spaceId: string) => {
        try {
          const spaceDocRef = doc(fs, `space`, spaceId);
          const docSnap = await getDoc(spaceDocRef)

          if (docSnap.exists())
            return docSnap.data() as Space

        } catch (e) {
          console.error("Error getting space doc: ", e);
        }

        return null
      },
      create: async (space: Space) => {
        try {
          const spaceDocRef = doc(fs, "space", space.id);
          await setDoc(spaceDocRef, space, { merge: true });

          return space;
        } catch (e) {
          console.error("Error adding document: ", e);
          return null
        }
      },
    },
    user: {
      get: async (email: string) => {
        const { spaceId, userId } = parseEmail(email)
        try {
          const userDocRef = doc(fs, `space/${spaceId}/user`, userId);
          const docSnap = await getDoc(userDocRef)

          if (docSnap.exists())
            return docSnap.data() as DBUser

        } catch (e) {
          console.error("Error getting user doc: ", e);
        }

        return null
      },
      create: async (user: DBUser) => {
        console.log("firebase user create")
        try {
          const userDocRef = doc(
            fs,
            `space/${user.spaceId}/user`,
            user.id
          );
          await setDoc(userDocRef, user, { merge: true });
          return user

        } catch (e) {
          console.error("Error adding user doc: ", e);
          return null
        }
      },
      updateIPLocationInfo: async (email: string, userIPLocationInfo: IPLocationInfo) => {
        console.log("firebase user updateIPLocationInfo")
        const { spaceId, userId } = parseEmail(email)
        try {
          const userDocRef = doc(
            fs,
            `space/${spaceId}/user`,
            userId
          );
          await updateDoc(userDocRef, { userIPLocationInfo });

        } catch (e) {
          console.error("Error updating user doc: ", e);
        }
      },
      updateFavorites: async (spaceId: string, userId: string, favorites: string[]) => {
        console.log("firebase user updateFavorites")
        try {
          const userDocRef = doc(
            fs,
            `space/${spaceId}/user`,
            userId
          );
          await updateDoc(userDocRef, { favorites });

        } catch (e) {
          console.error("Error updating user doc: ", e);
        }
      },
      updateCustomerProfile: async (spaceId: string, userId: string, customProfile: UserCustomProfile) => {
        console.log("firebase user updateCustomerProfile")
        try {
          const userDocRef = doc(
            fs,
            `space/${spaceId}/user`,
            userId
          );
          await updateDoc(userDocRef, { customProfile });

        } catch (e) {
          console.error("Error updating user doc: ", e);
        }
      },
      onChange: (email: string, callback: (changes: { changeType: DocumentChangeType, dbUser: DBUser }[]) => void) => {
        const { spaceId } = parseEmail(email)
        const q = query(collection(fs, `space/${spaceId}/user`));

        return onSnapshot(q, function (snapshot) {
          let changes: { changeType: DocumentChangeType, dbUser: DBUser }[] = []

          snapshot.docChanges().forEach(function (change) {
            changes.push({
              changeType: change.type,
              dbUser: change.doc.data() as DBUser
            })
          });
          callback(changes)
        });
      }
    },
    group: {
      create: async (spaceId: string, userId: string, newGroup: Group) => {
        try {
          const newGroupDoc = doc(collection(fs, `space/${spaceId}/user/${userId}/group`))
          newGroup.id = newGroupDoc.id
          await setDoc(newGroupDoc, newGroup);
          return newGroup

        } catch (e) {
          console.error("Error adding group doc: ", e);
          return null
        }
      },
      update: async (spaceId: string, userId: string, group: Group) => {
        try {
          const groupDocRef = doc(fs, `space/${spaceId}/user/${userId}/group/${group.id}`);
          await updateDoc(groupDocRef, { ...group });

        } catch (e) {
          console.error("Error updating group doc: ", e);
        }
      },
      delete: async (spaceId: string, userId: string, groupId: string) => {
        try {
          const groupDocRef = doc(fs, `space/${spaceId}/user/${userId}/group/${groupId}`);
          deleteDoc(groupDocRef);

        } catch (e) {
          console.error("Error deleting group doc: ", e);
        }
      },
      onChange: (email: string, callback: (changes: { changeType: DocumentChangeType, group: Group }[]) => void) => {
        const { spaceId, userId } = parseEmail(email)
        const q = query(collection(fs, `space/${spaceId}/user/${userId}/group`));

        return onSnapshot(q, function (snapshot) {
          let changes: { changeType: DocumentChangeType, group: Group }[] = []

          snapshot.docChanges().forEach(function (change) {
            changes.push({
              changeType: change.type,
              group: change.doc.data() as Group
            })
          });
          callback(changes)
        });
      }
    },
    timestamp: {
      now: Timestamp.now,
      fromDate: Timestamp.fromDate,
    }
  },
  presence: {
    setup: (spaceId: string, userId: string) => {
      // [START rtdb_and_local_fs_presence]
      // [START_EXCLUDE]
      var userStatusDatabaseRef = ref(database, `space/${spaceId}/timeStatus/${userId}`.replace(/\./g, '__'));

      var isOfflineForDatabase = {
        state: 'offline',
        last_changed: databaseServerTimestamp(),
      };

      var isOnlineForDatabase = {
        state: 'online',
        last_changed: databaseServerTimestamp(),
      };

      // [END_EXCLUDE]
      var userStatusFirestoreRef = doc(fs, `space/${spaceId}/user/${userId}`);

      // Firestore uses a different server timestamp value, so we'll 
      // create two more constants for Firestore state.
      var isOfflineForFirestore = {
        timePresence: {
          state: 'offline',
          last_changed: fsServerTimestamp(),
        }
      };

      var isOnlineForFirestore = {
        timePresence: {
          state: 'online',
          last_changed: fsServerTimestamp(),
        }
      };

      onValue(ref(database, '.info/connected'), function (snapshot) {
        console.log("presence val", snapshot.val())
        if (snapshot.val() === false) {
          // Instead of simply returning, we'll also set Firestore's state
          // to 'offline'. This ensures that our Firestore cache is aware
          // of the switch to 'offline.'
          setDoc(userStatusFirestoreRef, isOfflineForFirestore, { merge: true });
          return;
        };

        onDisconnect(userStatusDatabaseRef).set(isOfflineForDatabase).then(function () {
          console.log("presence onDisconnect")
          set(userStatusDatabaseRef, isOnlineForDatabase);

          // We'll also add Firestore set here for when we come online.
          setDoc(userStatusFirestoreRef, isOnlineForFirestore, { merge: true });
        });
      });
      // [END rtdb_and_local_fs_presence]
    }
  }
}

export default fb
