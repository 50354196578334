
import React, { useCallback, useMemo } from 'react';
import { Typography, Box, Avatar, Popover, Tooltip, Badge, styled } from '@mui/material';
import FavoriteIcon from '../../Icons/FavoriteIcon';
import { useUser } from '../../../contexts/UserContext';
import fb from '../../../firebase';
import { useApp } from '../../../contexts/AppContext';
import { DBUser } from '../../../types/Models';
import { helpers } from '../../../helpers';
import BinIcon from '../../Icons/BinIcon';
import SearchedText from '../../common/SearchedText';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));


interface Props {
  user: DBUser
}

const UserInfo: React.FC<Props> = ({ user }) => {
  const { currentDBUser, currentGroup, dbUsers, updateGroup } = useUser()
  const { appFilter, search } = useApp()

  const country = useMemo(() => user.customProfile?.location?.countryLongName || user.userIPLocationInfo?.country_name || '', [user.userIPLocationInfo?.country_name, user.customProfile?.location?.countryLongName])
  const flag = useMemo(() => helpers.user.getCountryFlag(user), [user])

  const responsibleManager = useMemo(() => dbUsers.find(u => u.email === user.customProfile?.reportingManagerEmail), [user.customProfile?.reportingManagerEmail, dbUsers])

  const location = useMemo(() => {
    let city = ''

    if (user.customProfile?.location?.cityName) {
      city = user.customProfile.location.cityName
    }
    else if (user.userIPLocationInfo?.city) {
      city = user.userIPLocationInfo.city
    }

    const cityText = city ? `${city}, ` : ''

    return `${cityText}${country}`
  }, [user.userIPLocationInfo?.city, user.customProfile?.location?.cityName, country])

  const email = useMemo(() => user.email, [user.email])
  const name = useMemo(() => helpers.user.getName(user), [user])

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isFavorite = useMemo(() => {
    if (!currentDBUser?.favorites)
      return false

    return currentDBUser.favorites.includes(email)
  }, [currentDBUser?.favorites, email])

  const showFavoriteIcon = useMemo(() => email !== currentDBUser?.email && appFilter.mode !== "groups", [email, currentDBUser?.email, appFilter.mode])
  const showRemoveFromGroupIcon = useMemo(() => appFilter.mode === "groups", [appFilter.mode])

  const toggleFavorite = useCallback(() => {
    if (!currentDBUser?.email)
      return

    let newFavorites = [...currentDBUser?.favorites || []]

    if (newFavorites.includes(email))
      newFavorites.splice(newFavorites.indexOf(email), 1)
    else
      newFavorites.push(email)

    fb.db.user.updateFavorites(currentDBUser?.spaceId, currentDBUser?.id, newFavorites)

  }, [currentDBUser?.favorites, currentDBUser?.email, currentDBUser?.id, currentDBUser?.spaceId, email])

  const removeFromGroup = useCallback(async () => {

    if (!currentGroup)
      return

    let newGroup = { ...currentGroup }

    if (newGroup.emails.includes(email)) {
      newGroup.emails = newGroup.emails.filter(e => e !== email)
      await updateGroup(newGroup)
    }

  }, [currentGroup, updateGroup, email])

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width={72}
        sx={{ cursor: 'pointer' }}
        aria-describedby={id}
        onClick={handleClick}
      >
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          variant="dot"
          invisible={user.timePresence?.state !== "online"}
        >
          <Avatar
            src={user.picture}
            sx={{
              width: 48,
              height: 48
            }}
            alt={`${name} image`}
          />
        </StyledBadge>
        <SearchedText
          fontSize={10}
          fontWeight={600}
          noWrap
          sx={{ mt: 1, maxWidth: "100%" }}
          text={name}
          search={search}
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      // transformOrigin={{
      //   vertical: 'top',
      //   horizontal: 'right',
      // }}
      >
        <Box sx={{ p: 2, minWidth: 270 }}>
          <Box sx={{ display: 'flex' }}>
            <Box flexGrow={1}>
              <SearchedText
                fontWeight={700}
                text={name}
                search={search}
              />
              <Box display="flex" alignItems="center">
                <img
                  src={flag}
                  alt={`${country} flag`}
                  style={{ maxWidth: 12, maxHeight: 12, marginRight: 4 }}
                />
                <Typography variant='caption'>{location}</Typography>
              </Box>
            </Box>
            {showFavoriteIcon &&
              <Tooltip title={isFavorite ? "Remove favorite" : "Add to favorite"}>
                <Box
                  sx={{ p: "1px", width: "20px", height: "20px", cursor: "pointer" }}
                  onClick={toggleFavorite}
                >
                  <FavoriteIcon mode="fill" selected={isFavorite} />
                </Box>
              </Tooltip>
            }
            {showRemoveFromGroupIcon &&
              <Tooltip title="Remove from group">
                <Box
                  sx={{ p: "1px", width: "20px", height: "20px", cursor: "pointer" }}
                  onClick={removeFromGroup}
                >
                  <BinIcon />
                </Box>
              </Tooltip>
            }
          </Box>
          {user.customProfile?.role &&
            <Box display="flex" gap={1}>
              <Typography fontSize="0.75rem">Role: </Typography>
              <Typography fontSize="0.75rem" fontWeight={600}>{user.customProfile?.role}</Typography>
            </Box>
          }
          {responsibleManager &&
            <Box display="flex" gap={1}>
              <Typography fontSize="0.75rem">Manager: </Typography>
              <Typography fontSize="0.75rem" fontWeight={600}>{helpers.user.getName(responsibleManager)}</Typography>
            </Box>
          }
        </Box>
      </Popover>
    </>
  );
}

export default UserInfo;
