import { useCallback, useEffect, useState } from 'react';

//@mui
import TextField from '@mui/material/TextField';
import { useApp } from '../../../contexts/AppContext';
import { useUser } from '../../../contexts/UserContext';

function GroupTitle() {
  const { appFilter, smallScreen } = useApp()
  const { currentGroup, updateGroup } = useUser()
  const [name, setName] = useState(currentGroup?.name || "")

  useEffect(() => {
    setName(currentGroup?.name || '')
  }, [currentGroup?.name])


  const saveName = useCallback(async () => {
    if (!currentGroup)
      return

    if (!name) {
      setName(currentGroup.name)
      return
    }

    let toUpdateGroup = { ...currentGroup } as Group
    toUpdateGroup.name = name

    updateGroup(toUpdateGroup)
  }, [name, currentGroup, updateGroup])

  if (appFilter.mode !== "groups" || !currentGroup)
    return null

  return (
    <TextField
      variant='outlined'
      placeholder='Group Name'
      value={name}
      fullWidth={smallScreen}
      onChange={e => setName(e.target.value)}
      onKeyDown={e => {
        if (e.key === "Enter") {
          saveName()
          e.preventDefault()
        }
        else if (e.key === "Escape") {
          setName(currentGroup?.name || '')
          e.preventDefault()
        }
      }}
      onBlur={saveName}
      InputProps={{
        sx: theme => ({
          backgroundColor: "transparent",
          p: 0,
          px: 3,
          mt: smallScreen ? 2 : 0,
          '& input': {
            padding: 0,
            fontSize: "1.25rem",
            fontWeight: 600
          },
          '& fieldset': {
            border: 'none',
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.background.default,
          },
          '&:hover': {
            outline: `2px solid ${theme.palette.background.default}`,
          }
        })
      }}
    />
  );
}

export default GroupTitle