import React from 'react';

//@mui
import Box from '@mui/material/Box';

import LogoIcon from '../../Icons/LogoIcon';
import SearchBox from './SearchBox';
import TimeSwitch from './TimeSwitch';
import UserIcon from './UserIcon';
import { useApp } from '../../../contexts/AppContext';
import InviteButton from './InviteButton';
import MobileMenu from './MobileMenu/MobileMenu';
import FilterMenu from './FilterMenu';
import GroupTitle from './GroupTitle';


function Header() {
  const { smallScreen } = useApp()

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={smallScreen ? 2 : 4}
      marginBottom={2}
    >
      <LogoIcon size={36} />

      {!smallScreen &&
        <SearchBox />
      }

      {/* ===============Spacer=============== */}
      <div style={{ flexGrow: 1 }}>
        {!smallScreen && <GroupTitle />}
      </div>
      {/* ===============Spacer=============== */}

      <FilterMenu />

      {!smallScreen &&
        <TimeSwitch />
      }

      <InviteButton />

      {!smallScreen &&
        <UserIcon />
      }

      {smallScreen &&
        <MobileMenu />
      }

    </Box>
  );
}

export default Header;
