import React, { CSSProperties } from 'react';

//@mui
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useApp } from '../../../contexts/AppContext';

const NumberCircle = styled('div')(({ theme }) => ({
  width: theme.spacing(6.5),
  height: theme.spacing(6.5),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: theme.spacing(12.5)
}))

function TimeSwitch() {
  const { timeFormat, setTimeFormat } = useApp()
  const theme = useTheme()

  const selectedStyle: CSSProperties = {
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
    color: theme.palette.mode === "dark" ? theme.palette.common.black : theme.palette.common.white,
  }

  return (
    <Box
      onClick={() => setTimeFormat(timeFormat === 12 ? 24 : 12)}
      sx={theme => ({
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.spacing(15),
        color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
        textTransform: "none",
        p: 1.25,
        height: theme.spacing(9),
        fontWeight: 700,
        lineHeight: theme.spacing(3),
        fontSize: theme.spacing(3),
        cursor: 'pointer',
        display: 'flex'
      })}
    >
      <NumberCircle style={timeFormat === 12 ? selectedStyle : {}}>12</NumberCircle>
      <NumberCircle style={timeFormat === 24 ? selectedStyle : {}}>24</NumberCircle>
    </Box>
  );
}

export default TimeSwitch