import React from 'react';
import { Box, Typography } from '@mui/material';
import LogoIcon from './Icons/LogoIcon';


function LoadingScreen() {

  return <Box
    display='flex'
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    height="100vh"
  >
    <LogoIcon size={48} />
    <Typography sx={{ mt: 3 }} variant='h4'>Loading...</Typography>
  </Box>
}

export default LoadingScreen;
