import { Autocomplete, Avatar, Box, TextField, Typography } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import IconContainer from '../../common/IconContainer'
import ArrowDownIcon from '../../Icons/ArrowDownIcon'
import { DBUser } from '../../../types/Models'
import { useUser } from '../../../contexts/UserContext'
import { helpers } from '../../../helpers'

interface Props {
  initUser: DBUser | null
  onSelect: (user: DBUser | null) => void
}

const ReportingManager = ({ initUser, onSelect }: Props) => {
  const { dbUsers, currentDBUser } = useUser()
  const [text, setText] = useState(initUser?.name || '')
  const [value, setValue] = useState<DBUser | null>(initUser)

  const searchedUsers = useMemo(() => dbUsers.filter(u => u.email !== currentDBUser?.email && [u.email, helpers.user.getName(u)].join('').toLowerCase().includes(text.toLowerCase())), [text, dbUsers, currentDBUser?.email])

  const handleSelect = useCallback((user: DBUser | null) => {
    setValue(user)
    onSelect(user)
  }, [onSelect])


  return (
    <Autocomplete
      options={searchedUsers}
      getOptionLabel={c => `${helpers.user.getName(c)} (${c.email})`}
      fullWidth

      inputValue={text}
      onInputChange={(e, newText) => setText(newText)}

      value={value}
      onChange={(e, value) => handleSelect(value)}

      isOptionEqualToValue={(option, value) => option.email === value.email}

      popupIcon={<IconContainer><ArrowDownIcon /></IconContainer>}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.grey[theme.palette.mode === "dark" ? 800 : 200]}`
          })}
          {...props}
        >
          <Avatar sx={{ mr: 2 }} src={option.picture} />
          <Typography fontSize="0.875rem">{helpers.user.getName(option)}</Typography>
        </Box>
      )}
      renderInput={params => (
        <form noValidate>
          <TextField
            {...params}
            placeholder='Pick your manager'
            autoComplete='off'
            size="small"
          />
        </form>
      )}
    />
  )
}

export default ReportingManager