import { Box, BoxProps } from '@mui/material'
import React from 'react'

interface Props extends BoxProps {
  size?: number
}

const IconContainer = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { size = 24, children, ...rest } = props
  return <Box
    ref={ref}
    {...rest}
    display="flex"
    justifyContent="center"
    alignItems="center"
    width={size}
    height={size}
    minWidth={size}
  >
    {children}
  </Box>
})

export default IconContainer