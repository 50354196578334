import React from 'react'
import { styled } from '@mui/material/styles';

interface Props {
  selected?: boolean
  mode: "fill" | "stroke"
}

const FavoriteIconSVG = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <path d="M8.55163 0.408493C8.73504 0.0368699 9.26496 0.03687 9.44837 0.408493L11.8226 5.21919C11.8954 5.36677 12.0362 5.46905 12.1991 5.49271L17.508 6.26415C17.9181 6.32374 18.0818 6.82772 17.7851 7.11699L13.9435 10.8616C13.8257 10.9765 13.7719 11.142 13.7997 11.3042L14.7066 16.5916C14.7766 17.0001 14.3479 17.3116 13.9811 17.1187L9.23267 14.6223C9.08701 14.5457 8.91299 14.5457 8.76733 14.6223L4.01888 17.1187C3.65207 17.3116 3.22335 17.0001 3.29341 16.5916L4.20028 11.3042C4.2281 11.142 4.17433 10.9765 4.05648 10.8616L0.21491 7.11699C-0.0818487 6.82772 0.0819064 6.32374 0.492017 6.26415L5.80094 5.49271C5.9638 5.46905 6.10458 5.36677 6.17741 5.21919L8.55163 0.408493Z" />
  </svg>
)

const FavoriteIcon = styled(FavoriteIconSVG)<Props>(({ theme, selected, mode = "fill" }) => {
  if (mode === "fill")
    return {
      '& path': {
        fill: selected ? "#FF5C64" : "#E5E5E5"
      }
    }

    return {
      '& path': {
        strokeWidth: "1.5",
        stroke: theme.palette.mode === "dark" ? "white" : "black"
      }
    }
})

export default FavoriteIcon