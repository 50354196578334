import { Autocomplete, TextField } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { helpers } from '../../../helpers'
import IconContainer from '../../common/IconContainer'
import ArrowDownIcon from '../../Icons/ArrowDownIcon'

interface Props {
  initLocation?: UserCustomProfileLocation
  onSelect: (city: google.maps.places.AutocompletePrediction | null) => void
}

const CustomLocation = ({ initLocation, onSelect }: Props) => {
const getDescription = (loc: UserCustomProfileLocation) => `${loc.cityName}, ${loc.provinceShortName}, ${loc.countryLongName}`

  const [citySelected, setCitySelected] = useState(initLocation !== null)
  const [text, setText] = useState(initLocation ? getDescription(initLocation) : '')
  const [cities, setCities] = useState<google.maps.places.AutocompletePrediction[]>([])
  const [value, setValue] = useState<google.maps.places.AutocompletePrediction | null>(initLocation ? {
    description: getDescription(initLocation),
    place_id: initLocation.placeId
  } as any : null)

  //load search for cities based on text
  useEffect(() => {
    let handle = setTimeout(async () => {
      if (!text || citySelected)
        return

      try {
        const predictions = await helpers.googleApis.cities(text)

        console.log("predictions", predictions)

        if (predictions) {
          setCities(predictions)
          return
        }
      } catch (error) {
        console.log("predictions error", error)
      }

      setCities([])

    }, 300);

    return () => {
      clearTimeout(handle)
    }
  }, [text, citySelected])

  const handleSelect = useCallback((city: google.maps.places.AutocompletePrediction | null) => {
    //to avoid an extra search after selection
    setCitySelected(true)
    setValue(city)
    onSelect(city)
  }, [onSelect])

  const handleTextChange = useCallback((newText: string) => {
    setText(newText)
    setCitySelected(false)
  }, [])


  return (
    <Autocomplete
      options={cities}
      getOptionLabel={c => c.description}
      fullWidth

      inputValue={text}
      onInputChange={(e, newText) => handleTextChange(newText)}

      value={value}
      onChange={(e, value) => handleSelect(value)}

      isOptionEqualToValue={(option, value) => option.place_id === value.place_id}

      popupIcon={<IconContainer><ArrowDownIcon /></IconContainer>}
      renderInput={params => (
        <form noValidate>
          <TextField
            {...params}
            placeholder='Search for your city'
            autoComplete='off'
            size="small"
            helperText="If provided, others will see you are located here and your timezone will be adjusted."
          />
        </form>
      )}
    />
  )
}

export default CustomLocation