import React, { useCallback, useState } from 'react';
import { Button, Typography, TextField, InputAdornment } from '@mui/material';
import { useUser } from '../../contexts/UserContext';
import { useApp } from '../../contexts/AppContext';
import EmailIcon from '../Icons/EmailIcon';

import emailDomains from '../../email-domains'

interface Props {
  onEmailSent: (email: string) => void
}

function LoginOptions({ onEmailSent }: Props) {
  const { loginWithGoogle, sendSingInLinkToEmail } = useUser()
  const { smallScreen, showAlert } = useApp()

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')

  const checkBusinessEmail = useCallback(async () => {
    const validateEmail = (e: string) => {
      return String(e)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };


    if (!validateEmail(email)) {
      showAlert({
        color: "warning",
        message: 'Enter a valid email address.',
      })
      return
    }

    const parts = email.toLowerCase().split('@')
    const domain = parts[parts.length - 1]

    if (emailDomains.includes(domain)) {
      showAlert({
        color: 'warning',
        message: 'Please enter your business/work email.'
      })
      return
    }

    setLoading(true)
    await sendSingInLinkToEmail(email)
    onEmailSent(email)
    setLoading(false)
  }, [showAlert, sendSingInLinkToEmail, email, onEmailSent])


  if (loading)
    return <Typography>Please wait...</Typography>

  return (
    <>
      <Typography
        fontWeight={700}
        fontSize="2rem"
        textAlign="center"
        gutterBottom
        sx={{
          maxWidth: smallScreen ? "330px" : "420px"
        }}
      >
        Compare Timings For Your Entire Remote Team
      </Typography>
      <Typography textAlign="center" sx={{ mb: 10 }}>
        The simplest way to display and compare local times for your global team.
      </Typography>
      <Button
        variant="contained"
        startIcon={<img src="/Google.svg" width={20} alt="Google Icon" />}
        onClick={loginWithGoogle}
        size="large"
        sx={theme => ({
          p: "12px 40px !important",
          // color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
          // borderColor: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
          // bgcolor: theme.palette.background.default,
          textTransform: "none",
          width: smallScreen ? "330px" : "400px",
          fontWeight: 600,
          lineHeight: 1.5
        })}
      >
        Sign in with Google Workspace
      </Button>
      <Typography
        sx={{ my: 2 }}
        textAlign="center"
      > - or - </Typography>
      <TextField
        sx={theme => ({
          width: smallScreen ? "330px" : "400px",
          bgcolor: theme.palette.mode === "dark" ? theme.palette.common.black : theme.palette.common.white,
          borderColor: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
          mb: 2
        })}
        size="small"
        placeholder='Enter business email'
        value={email}
        onChange={e => setEmail(e.target.value)}
        onKeyUp={async e => {
          if (e.key === "Enter") {
            e.preventDefault()
            checkBusinessEmail()
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          )
        }}
      />
      <Button
        variant="contained"
        onClick={checkBusinessEmail}
        size="large"
        color="secondary"
        sx={theme => ({
          p: "12px 40px !important",
          // color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
          // borderColor: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
          // bgcolor: theme.palette.background.default,
          textTransform: "none",
          width: smallScreen ? "330px" : "400px",
          fontWeight: 600,
          lineHeight: 1.5
        })}
      >
        Sign in
      </Button>
    </>
  );
}

export default LoginOptions
