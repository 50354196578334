import { useMemo } from 'react';
import { useUser } from '../../contexts/UserContext';
import TimeZoneBox from './TimeZoneBox/TimeZoneBox';
import { useApp } from '../../contexts/AppContext';
import { helpers } from '../../helpers';
import AllTimeZoneBoxes from './AllTimeZoneBoxes';
import _ from 'lodash';

const MainView = () => {
  const { customTimeOffsetMinutes, tick, appFilter } = useApp()
  const { filteredDBUsers: dbUsers, currentUserOffset, currentUserTime, groups } = useUser()

  const offsets = useMemo(() => {
    let offsetInfos: OffsetInfo[] = []

    const usersOffsetInfos = helpers.offsetInfo.getUsersOffsetInfos(dbUsers, currentUserTime, tick, customTimeOffsetMinutes)
    offsetInfos.push(...usersOffsetInfos)

    //load all groups offsets
    if (appFilter.mode === "all-people") {
      groups.forEach(group => {
        const groupOffsetInfos = helpers.offsetInfo.getGroupOffsetInfos(group.places, group.people, currentUserTime, tick, customTimeOffsetMinutes)
        console.log("groupOffsetInfos", groupOffsetInfos)
        offsetInfos.push(...groupOffsetInfos)
      })
    }

    return _.chain(offsetInfos)
      .uniqBy(o => o.offset)
      .orderBy(o => o.offset)
      .value()
  }, [dbUsers, customTimeOffsetMinutes, tick, currentUserTime, appFilter.mode, groups])

  //if app filter mode is "favorites" show my timezone when there are people in it
  const currentOffsetHasPeople = useMemo(() => appFilter.mode !== "favorites" || offsets.some(o => o.offset === currentUserOffset), [currentUserOffset, offsets, appFilter.mode])

  return (
    <>

      {currentOffsetHasPeople &&
        <TimeZoneBox
          offset={currentUserOffset}
          time={currentUserTime}
          name={helpers.getUTCOffsetTitle(currentUserOffset)}
        />
      }

      <AllTimeZoneBoxes
        currentUserTime={currentUserTime}
        //others' offsets
        offsets={offsets.filter(o => o.offset !== currentUserOffset)}
      />
    </>
  )
}

export default MainView