import React from 'react'
import { styled } from '@mui/material/styles';

const ResetIconSVG = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M6.0733 3.38664C6.6533 3.21331 7.2933 3.09998 7.99997 3.09998C11.1933 3.09998 13.78 5.68664 13.78 8.87998C13.78 12.0733 11.1933 14.66 7.99997 14.66C4.80664 14.66 2.21997 12.0733 2.21997 8.87998C2.21997 7.69331 2.57997 6.58664 3.1933 5.66664" stroke="#FF1E39" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.24658 3.54671L7.17325 1.33337" stroke="#FF1E39" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.24658 3.54663L7.49325 5.18663" stroke="#FF1E39" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

const ResetIcon = styled(ResetIconSVG)(({ theme }) => {
  return {
  }
})

export default ResetIcon