import { Alert, Snackbar, useMediaQuery } from "@mui/material";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AppAlert } from "../types/Models";
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom'

type TimeFormat = 12 | 24

interface AppContextType {
  appFilter: AppFilter
  customTimeOffsetMinutes: number | null
  editProfileIsOpen: boolean
  menuIsOpen: boolean
  search: string
  smallHeightScreen: boolean
  smallScreen: boolean
  tick: number
  timeFormat: TimeFormat
  reset: () => void
  setAppFilter: (filter: AppFilter) => void
  setEditProfileIsOpen: (open: boolean) => void
  setMenuIsOpen: (value: boolean) => void
  setCustomTimeOffsetMinutes: (offsetMinutes: number | null) => void
  setSearch: (value: string) => void
  setTimeFormat: (newTimeFormat: TimeFormat) => void
  showAlert: (alert: AppAlert) => void
}

export const DEFAULT_APP_FILTER_MODE: AppFilterMode = "my-team"

const AppContext = createContext<AppContextType | undefined>(undefined);

const getStoredTimeFormat = () => {
  const s = localStorage.getItem("TIME_FORMAT")

  if (s) {
    const n = parseInt(s)

    if (n && !isNaN(n))
      return n as TimeFormat
  }

  return null
}

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [alert, setAlert] = useState<AppAlert | null>(null)
  const [timeFormat, setTimeFormatState] = useState<TimeFormat>(getStoredTimeFormat() || 12)
  const [customTimeOffsetMinutes, setCustomTimeOffsetMinutes] = useState<number | null>(null)
  const [search, setSearch] = useState("")
  const [tick, setTick] = useState(new Date().getTime())
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [editProfileIsOpen, setEditProfileIsOpen] = useState(false)

  const currentLocation = useLocation()
  const initAppFilter = useMemo(() => {
    let result: AppFilter = { mode: DEFAULT_APP_FILTER_MODE };

    const pathParts = currentLocation.pathname.split('/').filter(Boolean)
    if (pathParts && pathParts.length > 0) {
      if (pathParts[0] === "favorites")
        result = { mode: "favorites" }
      else if (pathParts[0] === "all")
        result = { mode: "all-people" }
      else if (pathParts[0] === "groups" && pathParts.length > 1)
        result = { mode: "groups", selectedGroupId: pathParts[1] }
    }

    return result
  }, [currentLocation])

  const navigate = useNavigate()
  const [appFilter, setAppFilter] = useState<AppFilter>(initAppFilter)

  //navigate based on appFilter
  useEffect(() => {
    let url = ''
    switch (appFilter.mode) {
      case "favorites":
        url = "favorites"
        break;
      case "all-people":
        url = "all"
        break;
      case "groups":
        url = `groups/${appFilter.selectedGroupId || ''}`
        break
    }

    navigate(url)
  }, [appFilter, navigate])

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const smallHeightScreen = useMediaQuery('(max-height:620px)');

  const reset = useCallback(() => {
    setAlert(null)
    setCustomTimeOffsetMinutes(null)
    setSearch('')
  }, [])


  const setTimeFormat = useCallback((newTimeFormat: TimeFormat) => {
    localStorage.setItem('TIME_FORMAT', newTimeFormat.toString())
    setTimeFormatState(newTimeFormat)
  }, [])

  useEffect(() => {
    let handle = setInterval(() => {
      setTick(new Date().getTime())
    }, 10 * 1000)

    return () => {
      clearInterval(handle)
    }
  }, [])

  return (
    <AppContext.Provider value={{
      appFilter,
      customTimeOffsetMinutes,
      editProfileIsOpen,
      menuIsOpen,
      search,
      smallHeightScreen,
      smallScreen,
      tick,
      timeFormat,
      reset,
      setAppFilter,
      setEditProfileIsOpen,
      setMenuIsOpen,
      setCustomTimeOffsetMinutes,
      setSearch,
      setTimeFormat,
      showAlert: setAlert
    }}
    >
      {children}
      {alert &&
        <Snackbar
          open={!!alert}
          autoHideDuration={alert.permanent ? null : 6000}
          onClose={() => setAlert(null)}
          anchorOrigin={{
            horizontal: "center",
            vertical: "top"
          }}
        >
          <Alert onClose={() => setAlert(null)} severity={alert?.color} sx={{ width: '100%' }}>
            {alert?.message}
          </Alert>
        </Snackbar>
      }
    </AppContext.Provider>
  );
};

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useApp must be used within a AppProvider");
  }
  return context;
};
