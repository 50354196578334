import React from 'react'
import { styled } from '@mui/material/styles';

const MyTeamIconSVG = (props: any) => (
  <svg viewBox="0 0 24 24" width="16" height="16" fill="none" {...props}>
    <path d="m14.16 10.4-5-3.57c-.7-.5-1.63-.5-2.32 0l-5 3.57c-.53.38-.84.98-.84 1.63V20c0 .55.45 1 1 1h4v-6h4v6h4c.55 0 1-.45 1-1v-7.97c0-.65-.31-1.25-.84-1.63"></path>
    <path d="M21.03 3h-9.06C10.88 3 10 3.88 10 4.97l.09.09c.08.05.16.09.24.14l5 3.57c.76.54 1.3 1.34 1.54 2.23H19v2h-2v2h2v2h-2v4h4.03c1.09 0 1.97-.88 1.97-1.97V4.97C23 3.88 22.12 3 21.03 3M19 9h-2V7h2z"></path>
  </svg>
)

const MyTeamIcon = styled(MyTeamIconSVG)(({ theme }) => {
  return {
    '& path': {
      stroke: theme.palette.mode === "dark" ? "white" : "black",
      strokeWidth: 1.5
    }
  }
})

export default MyTeamIcon