
import React, { useCallback, useMemo } from 'react';
import { Typography, Box, Avatar, Popover, Tooltip } from '@mui/material';
import { useUser } from '../../../contexts/UserContext';
import { helpers } from '../../../helpers';
import BinIcon from '../../Icons/BinIcon';
import PersonIcon from '../../Icons/PersonIcon';
import SearchedText from '../../common/SearchedText';
import { useApp } from '../../../contexts/AppContext';


interface Props {
  person: GroupPerson
}

const PersonInfo: React.FC<Props> = ({ person }) => {
  const { currentGroup, updateGroup } = useUser()
  const { search } = useApp()

  const flag = useMemo(() => person.place.country_short_name && helpers.getFlagByCountryShortName(person.place.country_short_name), [person.place.country_short_name])

  const location = useMemo(() => {
    if (person.place.country_long_name) {
      return `${person.place.name}, ${person.place.country_long_name}`
    }
    else
      return person.place.name
  }, [person.place])

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeFromGroup = useCallback(async () => {
    if (!currentGroup)
      return

    let newGroup = { ...currentGroup }

    if (newGroup.people.findIndex(p => p.id === person.id) >= 0) {
      newGroup.people = newGroup.people.filter(p => p.id !== person.id)
      await updateGroup(newGroup)
    }

  }, [currentGroup, updateGroup, person.id])

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width={72}
        sx={{ cursor: 'pointer' }}
        aria-describedby={id}
        onClick={handleClick}
      >
        <Avatar
          sx={{
            width: 48,
            height: 48
          }}
          alt="Person"
        >
          <PersonIcon />
        </Avatar>
        <SearchedText
          fontSize={10}
          fontWeight={600}
          noWrap
          sx={{ mt: 1, maxWidth: "100%" }}
          text={person.name}
          search={search}
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      // transformOrigin={{
      //   vertical: 'top',
      //   horizontal: 'right',
      // }}
      >
        <Box sx={{ p: 2, minWidth: 270 }}>
          <Box sx={{ display: 'flex' }}>
            <Box flexGrow={1}>
              <SearchedText
                fontWeight={700}
                text={person.name}
                search={search}
              />
              <Box display="flex" alignItems="center">
                {flag &&
                  <img
                    src={flag}
                    alt={`${location} flag`}
                    style={{ maxWidth: 12, maxHeight: 12, marginRight: 4 }}
                  />
                }
                <Typography variant='caption'>{location}</Typography>
              </Box>
            </Box>
            <Tooltip title="Remove from group">
              <Box
                sx={{ p: "1px", width: "20px", height: "20px", cursor: "pointer" }}
                onClick={removeFromGroup}
              >
                <BinIcon />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

export default PersonInfo;
