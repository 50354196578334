import React, { useCallback, useMemo } from 'react';

//@mui
import Button from '@mui/material/Button';

import { useApp } from '../../../contexts/AppContext';
import AllPeopleIcon from '../../Icons/AllPeopleIcon';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import ArrowDownIcon from '../../Icons/ArrowDownIcon';
import ArrowUpIcon from '../../Icons/ArrowUpIcon';
import FavoriteIcon from '../../Icons/FavoriteIcon';
import GroupIcon from '../../Icons/GroupIcon';
import { useUser } from '../../../contexts/UserContext';
import CloseIcon from '../../Icons/CloseIcon';
import MyTeamIcon from '../../Icons/MyTeamIcon';

const ALL_TEXT = "All people and places"

function FilterMenu() {
  const { smallScreen, appFilter, setAppFilter } = useApp()
  const { groups, deleteGroup, addNewGroup, currentDBUser } = useUser()

  const myTeamName = useMemo(() => currentDBUser?.spaceId || "My Team", [currentDBUser?.spaceId])

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [toDeleteGroupId, setToDeleteGroupId] = React.useState<string | null>(null)
  const handleCloseDialog = () => setToDeleteGroupId(null)

  const text = useMemo(() => {
    switch (appFilter.mode) {
      case "favorites": return "Favorites"
      case "groups":
        // const col = groups.find(c => c.id === appFilter.selectedGroupId)
        // return col?.name || "New Group "
        return "Groups"
      case "all-people":
        return ALL_TEXT
      case "my-team":
      default: return myTeamName
    }
  }, [appFilter.mode, myTeamName])

  const icon = useMemo(() => {
    switch (appFilter.mode) {
      case "favorites": return <FavoriteIcon mode="stroke" />
      case "groups": return <GroupIcon />
      case "all-people": return <AllPeopleIcon />
      case "my-team":
      default: return <MyTeamIcon />
    }
  }, [appFilter.mode])

  const newGroup = useCallback(() => addNewGroup(), [addNewGroup])

  const handleDeleteGroup = useCallback(async () => {
    if (!toDeleteGroupId)
      return

    await deleteGroup(toDeleteGroupId)
    setToDeleteGroupId(null)
    handleClose()
  }, [toDeleteGroupId, deleteGroup])

  return (
    <>
      <Button
        startIcon={icon}
        endIcon={open ? <ArrowUpIcon /> : <ArrowDownIcon />}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={theme => ({
          backgroundColor: theme.palette.background.default,
          borderRadius: theme.spacing(4),
          color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
          textTransform: "none",
          fontWeight: 600,
          px: 4,
          py: 2,
          mr: smallScreen ? 0 : 2,
          height: theme.spacing(8),
          lineHeight: theme.spacing(3)
        })}
      >
        {text}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'right',
        }}
      >
        <MenuItem sx={{ width: 230 }} onClick={() => {
          setAppFilter({ mode: "my-team" })
          handleClose()
        }}>
          <ListItemIcon><MyTeamIcon /></ListItemIcon>
          <ListItemText>{myTeamName}</ListItemText>
        </MenuItem>
        {groups.length > 0 &&
          <MenuItem onClick={() => {
            setAppFilter({ mode: "all-people" })
            handleClose()
          }}>
            <ListItemIcon><AllPeopleIcon /></ListItemIcon>
            <ListItemText>{ALL_TEXT}</ListItemText>
          </MenuItem>
        }
        <MenuItem onClick={() => {
          setAppFilter({ mode: "favorites" })
          handleClose()
        }}>
          <ListItemIcon><FavoriteIcon mode='stroke' /></ListItemIcon>
          <ListItemText>Favorites</ListItemText>
        </MenuItem>

        <Divider />

        <MenuItem disabled>
          <ListItemText><Typography variant='caption'>Groups:</Typography></ListItemText>
        </MenuItem>

        {groups.map(c => (
          <MenuItem key={c.id} onClick={() => {
            setAppFilter({ mode: "groups", selectedGroupId: c.id })
            handleClose()
          }}>
            <ListItemIcon><GroupIcon /></ListItemIcon>
            <ListItemText>{c.name}</ListItemText>
            <IconButton onClick={e => {
              e.stopPropagation()
              setToDeleteGroupId(c.id)
            }}>
              <CloseIcon isGrey />
            </IconButton>
          </MenuItem>
        ))}

        {groups.length > 0 &&
          <Divider />
        }

        <MenuItem onClick={() => {
          newGroup()
          handleClose()
        }}>
          {/* <ListItemIcon><GroupIcon /></ListItemIcon> */}
          <ListItemText>New Group</ListItemText>
        </MenuItem>
      </Menu>
      <Dialog
        open={toDeleteGroupId !== null}
        onClose={handleCloseDialog}
      >
        <DialogTitle>
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure to delete ${groups.find(g => g.id === toDeleteGroupId)?.name || "this group"}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button onClick={handleDeleteGroup}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FilterMenu