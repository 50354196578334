
import React, { useMemo, useState } from 'react';
import { Paper, Box, Typography, Avatar } from '@mui/material';
import { useUser } from '../../../contexts/UserContext';
import { useApp } from '../../../contexts/AppContext';
import _ from 'lodash';
import ShowTime from './ShowTime';
import UserInfo from './UserInfo';
import { helpers } from '../../../helpers';
import PlaceItem from './PlaceItem';
import AddUserIcon from '../../Icons/AddUserIcon';
import AddPeopleDialog from './AddPeopleDialog';
import PersonInfo from './PersonInfo';

interface Props {
  offset: number
  time: Date
  name: string
}

const TimeZoneBox: React.FC<Props> = ({ offset, time, name }) => {
  const { search, smallScreen, appFilter } = useApp()
  const { filteredDBUsers: dbUsers, currentUserTime, currentUserOffset, currentGroup, groups } = useUser()

  const [addPeopleDialogOpen, setAddPeopleDialogOpen] = useState(false)

  const users = useMemo(() => _.chain(dbUsers)
    .filter(u => helpers.user.getOffset(u, currentUserTime) === offset &&
      helpers.user.getName(u).toLowerCase().indexOf(search.toLowerCase()) >= 0)
    .uniq()
    .value(), [dbUsers, offset, search, currentUserTime])

  const people = useMemo(() => {
    if (appFilter.mode !== "all-people" && appFilter.mode !== "groups")
      return []

    let result: GroupPerson[] = []

    if (appFilter.mode === "groups") {
      if (!currentGroup?.people)
        return []

      result.push(...currentGroup.people)
    }

    if (appFilter.mode === "all-people") {
      groups.forEach(g => {
        if (g.people)
          result.push(...g.people)
      })
    }

    return _.chain(result)
      .filter(p => helpers.getTimezoneOffset(p.place.timezone, currentUserTime) === offset &&
        p.name.toLowerCase().indexOf(search.toLowerCase()) >= 0)
      .uniq()
      .value()
  }, [currentGroup?.people, appFilter.mode, currentUserTime, offset, search, groups])

  const places = useMemo(() => {
    if (appFilter.mode !== "all-people" && appFilter.mode !== "groups")
      return []

    let result: GroupPlace[] = []

    if (appFilter.mode === "groups") {
      if (!currentGroup?.places)
        return []

      result.push(...currentGroup.places)
    }

    if (appFilter.mode === "all-people") {
      groups.forEach(g => {
        if (g.people)
          result.push(...g.places)
      })
    }

    return _.chain(result)
      .filter(place => helpers.getTimezoneOffset(place.timezone, time) === offset &&
        `${place.name},${place.country_long_name}`.toLowerCase().indexOf(search) >= 0
      )
      .uniqBy(place => place.name)
      .value()
  }, [currentGroup?.places, offset, time, search, appFilter.mode, groups])

  const offsetDif = useMemo(() => offset - currentUserOffset, [offset, currentUserOffset])

  const renderTimeInfo = (marginBottom: number) => {

    return (
      <Box marginBottom={{ marginBottom }}>
        <ShowTime time={time} />
        <Box
          display="flex"
          justifyContent={smallScreen ? "flex-start" : "flex-end"}
          alignItems="center"
          paddingTop={3}
          sx={smallScreen ? {
            '& p': {
              fontSize: "0.875rem !important",
              lineHeight: "24px"
            }
          } : {}}
        >
          {offsetDif !== 0 &&
            <Typography
              variant='caption'
              sx={theme => {
                const p = theme.palette
                return {
                  color: p.mode === "dark" ? p.success.light : p.success.main,
                  bgcolor: p.grey[p.mode === "dark" ? 700 : 100],
                  borderRadius: 17.5,
                  px: 2,
                  mr: 2,
                  height: "16px",
                  lineHeight: "16px"
                }
              }}
            >{`${offsetDif > 0 ? "+" : ""}${offsetDif}H`}</Typography>
          }
          <Typography
            fontWeight={600}
            sx={theme => ({
              color: theme.palette.grey[500]
            })}
          >{smallScreen ? `${name}, ` : `(${name})`}</Typography>
          {smallScreen &&
            <Typography
              fontWeight={600}
              sx={theme => ({ color: theme.palette.grey[500] })}
            >{helpers.getDateString(time)}</Typography>
          }
        </Box>
      </Box>)
  }

  if (!people.length && !places.length && !users.length)
    return null

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          p: smallScreen ? 4 : 6,
          mt: 4,
          '&:first-of-type': {
            mt: 0
          },
          // '&:hover .add-people-to-group': {
          //   display: 'flex'
          // }
        }}
      >
        {smallScreen && renderTimeInfo(36)}
        <Box display="flex">
          <Box flexGrow={1} display='flex' flexWrap="wrap" gap="8px">
            {users.map(u => (
              <UserInfo
                key={u.email}
                user={u}
              />
            ))}
            {(appFilter.mode === "groups" || appFilter.mode === "all-people") &&
              <>
                {people.map(p => (
                  <PersonInfo key={p.id} person={p} />
                ))}
                {appFilter.mode === "groups" &&
                  <Box
                    className="add-people-to-group"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    width={72}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setAddPeopleDialogOpen(true)}
                  >
                    <Avatar sx={{ width: 48, height: 48 }}>
                      <AddUserIcon />
                    </Avatar>
                    <Typography
                      fontSize={10}
                      fontWeight={600}
                      noWrap
                      sx={{ mt: 1, maxWidth: "100%" }}>Add People</Typography>
                  </Box>
                }
              </>
            }
          </Box>
          {!smallScreen && renderTimeInfo(6)}
        </Box>
        {places.length > 0 &&
          <Box sx={theme => ({
            px: 2,
            py: 3,
            pb: 0,
            mt: 5,
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            display: 'flex',
            flexWrap: "wrap",
            gap: 2.5
          })}
          >
            {places.map(place => (
              <PlaceItem key={place.name} place={place} />
            ))}
          </Box>
        }
      </Paper>
      <AddPeopleDialog
        boxUsers={users}
        boxPlaces={places}
        boxPeople={people}
        open={addPeopleDialogOpen}
        onClose={() => setAddPeopleDialogOpen(false)}
      />
    </>
  );
}

export default TimeZoneBox;
