import React from 'react';

//@mui
import { alpha, Avatar, Box, Typography, MenuItem, ListItemText, Switch } from '@mui/material';
import CircleIconButton from '../CircleIconButton';
import MenuIcon from '../../../Icons/MenuIcon';
import CloseIcon from '../../../Icons/CloseIcon';
import { useUser } from '../../../../contexts/UserContext';
import SearchBox from '../SearchBox';
import { useApp } from '../../../../contexts/AppContext';
import { useSettings } from '../../../../contexts/SettingsContext';
import { helpers } from '../../../../helpers';

function MobileMenu() {
  const { currentDBUser, logout } = useUser()
  const { timeFormat, setTimeFormat, menuIsOpen, setMenuIsOpen, setEditProfileIsOpen } = useApp()
  const { mode, setThemeMode } = useSettings()

  if (!currentDBUser)
    return null

  return (
    <>
      <CircleIconButton revert onClick={() => setMenuIsOpen(true)}>
        <MenuIcon />
      </CircleIconButton>
      {menuIsOpen &&
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          zIndex={1000}
          bgcolor={theme => alpha(theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black, 0.75)}
        >

          {/* ---------------Menu Box----------------- */}
          <Box
            display='flex'
            flexDirection="column"
            // height="75vh"
            bgcolor={theme => theme.palette.grey[theme.palette.mode === "dark" ? 900 : 200]}
            borderRadius="0px 0px 16px 16px"
            padding="16px 16px"
          >

            {/* ---------------Header----------------- */}
            <Box display="flex">
              <Avatar
                src={currentDBUser.picture || ''}
                sx={{
                  width: 40,
                  height: 40
                }}
                alt={helpers.user.getName(currentDBUser)}
              />
              <Box marginLeft={3} flexGrow={1}>
                <Typography fontWeight={700}>{helpers.user.getName(currentDBUser)}</Typography>
                <Typography component="div" variant='caption'>{currentDBUser.email}</Typography>
              </Box>
              <CircleIconButton revert onClick={() => setMenuIsOpen(false)}>
                <CloseIcon />
              </CircleIconButton>
            </Box>

            {/* ---------------Search Box----------------- */}
            <Box padding="16px 0px">
              <SearchBox fullWidth onEnter={() => setMenuIsOpen(false)} />
            </Box>


            {/* ---------------MenuItems----------------- */}
            {/* <InviteButton asMenuItem /> */}

            <MenuItem onClick={() => setTimeFormat(timeFormat === 12 ? 24 : 12)}>
              <ListItemText>24 Hour Timing</ListItemText>
              <Switch
                color='default'
                checked={timeFormat === 24}
              />
            </MenuItem>

            <MenuItem onClick={() => setThemeMode(mode === "light" ? "dark" : "light")}>
              <ListItemText>Dark Theme</ListItemText>
              <Switch
                color='default'
                checked={mode === "dark"}
              />
            </MenuItem>

            {/* ===============Spacer=============== */}
            {/* <div style={{ flexGrow: 1 }}></div> */}
            {/* ===============Spacer=============== */}

            <MenuItem onClick={() => {
              setMenuIsOpen(false)
              setEditProfileIsOpen(true)
            }}>
              <ListItemText>Edit Profile</ListItemText>
            </MenuItem>

            <MenuItem onClick={logout}>
              <ListItemText>Logout</ListItemText>
            </MenuItem>

          </Box>
        </Box>
      }
    </>
  );
}

export default MobileMenu;
