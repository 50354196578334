import moment from 'moment-timezone';
import { DBUser } from './types/Models';

const getUserIP = async () => {
  const response = await fetch(`https://api.ipify.org`);

  if (response.ok) return await response.text();

  return null;
};

const getUserIPLocationInfo = async (ip: string) => {
  const IP_LOCATION_API_KEY = "bef40e5670e04a9b87c7fec478550c26";
  const response = await fetch(
    `https://api.ipgeolocation.io/ipgeo?apiKey=${IP_LOCATION_API_KEY}&ip=${ip}`
  );

  if (response.ok) return (await response.json()) as IPLocationInfo;

  return null;
};

const getIPLocationInfo: () => Promise<IPLocationInfo | null> = async () => {
  const STORAGE_LAST_LOGIN_INFO_KEY = "lastIPInfo";
  const lastStoredIPInfoString = null//  localStorage.getItem(STORAGE_LAST_LOGIN_INFO_KEY);
  let lastStoredIPInfo: IPLocationInfo | null = null;

  if (lastStoredIPInfoString)
    lastStoredIPInfo = JSON.parse(lastStoredIPInfoString);

  console.log("lastStoredIPInfo", lastStoredIPInfo);

  // const ip = "211.67.32.180"
  const ip = await getUserIP();

  if (!ip) return lastStoredIPInfo;

  if (lastStoredIPInfo?.ip === ip) return lastStoredIPInfo;

  const ipInfo = await getUserIPLocationInfo(ip);

  if (!ipInfo) return null;

  localStorage.setItem(STORAGE_LAST_LOGIN_INFO_KEY, JSON.stringify(ipInfo));

  return ipInfo;
};

const getTimeByOffset = (tick: number, offset: number, customTimeOffsetMinutes: number | null) => {
  // create Date object for current location
  var d = new Date(tick);

  // convert to msec
  // subtract local time zone offset
  // get UTC time in msec
  var utc = d.getTime() + (d.getTimezoneOffset() * 60000);

  // create new Date object for different city
  // using supplied offset
  var nd = new Date(utc + (3600000 * offset));

  return moment(nd).add(customTimeOffsetMinutes || 0, 'm').toDate()
}

const GOOGLE_API_KEY = "AIzaSyAd-Bn41MSN5cTjxVnVZf6oc4UJZNHHGSw"
const googleApis = {
  cities: (term: string) => {
    return new Promise((res: (result: google.maps.places.AutocompletePrediction[] | null) => void, rej) => {
      const service = new google.maps.places.AutocompleteService()
      service.getPlacePredictions({
        input: term,
        types: ["(cities)"]
      },
        (result, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK)
            res(result)
          else
            rej({
              status, result
            })
        })
    })
  },
  getCityTimezoneByPlaceId: (placeId: string) => {
    return new Promise((res: (result: { timezone: string, country?: google.maps.GeocoderAddressComponent, province?: google.maps.GeocoderAddressComponent }) => void, rej) => {
      const service = new google.maps.Geocoder()
      service.geocode({
        placeId
      },
        async (result, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            if (!result || !result.length) {
              rej("No result!")
              return
            }

            console.log("geocoder result", result)

            const latLng = result[0].geometry.location
            const country = result[0].address_components.find(a => a.types.includes("country"))
            const province = result[0].address_components.find(a => a.types.includes("administrative_area_level_1"))
            const response = await fetch(`https://maps.googleapis.com/maps/api/timezone/json?location=${latLng.lat()},${latLng.lng()}&timestamp=${new Date().getTime() / 1000}&key=${GOOGLE_API_KEY}`)

            if (response.ok) {
              const timezoneResult = await response.json()
              res({
                timezone: timezoneResult.timeZoneId,
                country,
                province
              })
              return
            }

            rej("Error in fetch timezone")
          }
          else
            rej({
              status, result
            })
        })
    })
  }
}
const getTimezoneOffset = (timezoneName: string, time: Date) => (moment.tz.zone(timezoneName)?.utcOffset(time.getTime()) || 0) / -60
const getUserTimezone = (user: DBUser | undefined | null) => !user ? '' : user.customProfile?.location?.timezone || user.userIPLocationInfo?.time_zone.name || ''
const getFlagByCountryShortName = (countryShortName: string) => `https://ipgeolocation.io/static/flags/${countryShortName.toLowerCase()}_64.png`
const getUTCOffsetTitle = (offset: number) => offset === 0 ? 'UTC' : `UTC${offset > 0 ? "+" : ""}${offset}`
const getTimezoneAbbr = (timezone: string, offset: number, time: Date) => {
  const abbr = moment.tz.zone(timezone)?.abbr(time.getTime()) || ''

  if (!abbr || /\d/.test(abbr))
    return getUTCOffsetTitle(offset)

  return abbr
}

const getGroupOffsetInfos = (
  groupPlaces: GroupPlace[] | undefined,
  groupPeople: GroupPerson[] | undefined,
  currentUserTime: Date,
  tick: number,
  customTimeOffsetMinutes: number | null) => {
  let offsetInfos: OffsetInfo[] = []
  let places: GroupPlace[] = []

  if (groupPeople)
    places.push(...groupPeople.map(p => p.place))

  if (groupPlaces)
    places.push(...groupPlaces)

  places.forEach(place => {
    const offset = getTimezoneOffset(place.timezone, currentUserTime)

    if (offsetInfos.findIndex(oi => oi.offset === offset) >= 0)//this timezone/offset is already in the list
      return

    const time = getTimeByOffset(tick, offset, customTimeOffsetMinutes)
    // const abbr = helpers.getTimezoneAbbr(tz.name)
    offsetInfos.push({
      time,
      day: helpers.getDateString(time),
      name: helpers.getUTCOffsetTitle(offset),
      // name: helpers.getTimezoneAbbr(place.timezone, time),
      offset
    })
  })

  return offsetInfos
}

const getUsersOffsetInfos = (
  dbUsers: DBUser[],
  currentUserTime: Date,
  tick: number,
  customTimeOffsetMinutes: number | null
  ) => {
  let offsetInfos: OffsetInfo[] = []

  dbUsers.forEach(u => {
    const offset = helpers.user.getOffset(u, currentUserTime)
    if (offsetInfos.findIndex(oi => oi.offset === offset) >= 0)//this timezone/offset is already in the list
      return

    const time = helpers.getTimeByOffset(tick, offset, customTimeOffsetMinutes)
    // const abbr = helpers.getTimezoneAbbr(u.userIPLocationInfo?.time_zone.name || '')
    offsetInfos.push({
      time,
      day: helpers.getDateString(time),
      name: helpers.getUTCOffsetTitle(offset),
      // name: helpers.getTimezoneAbbr(u.userIPLocationInfo?.time_zone.name || '', time),
      offset
    })
  })
  return offsetInfos
}

export const helpers = {
  getAllTimezones: () => moment.tz.names(),
  getTimeByOffset,
  getDateString: (time: Date) => moment(time).format("ddd, Do MMM"),

  getTimezoneAbbr,
  getTimezoneOffset,
  getUTCOffsetTitle,

  googleApis,
  getFlagByCountryShortName: getFlagByCountryShortName,
  user: {
    getCountryFlag: (user: DBUser | undefined | null) => user?.customProfile?.location?.countryShortName ? getFlagByCountryShortName(user.customProfile.location.countryShortName) : user?.userIPLocationInfo?.country_flag || '',
    getIPLocationInfo,
    getName: (user: DBUser | undefined | null) => !user ? '' : `${user.customProfile?.firstName || user.given_name} ${user.customProfile?.lastName || user.family_name}`,
    getOffset: (user: DBUser | undefined | null, currentTime: Date) => getTimezoneOffset(getUserTimezone(user), currentTime),
    getTimezone: getUserTimezone,
  },
  offsetInfo: {
    getGroupOffsetInfos,
    getUsersOffsetInfos
  }
}