import React from 'react'
import { styled } from '@mui/material/styles';

const BinIconSVG = (props: any) => (
  <svg viewBox="0 0 24 24" width="16" height="16" {...props}>
    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM19 4h-3.5l-1-1h-5l-1 1H5v2h14z"></path>
  </svg>
)

const BinIcon = styled(BinIconSVG)(({ theme }) => {
  return {
    '& path': {
      fill: theme.palette.grey[theme.palette.mode === "dark" ? 500 : 700]
    }
  }
})

export default BinIcon