import { useCallback } from 'react';
import InviteIcon from '../../Icons/InviteIcon';
import { Button, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useApp } from '../../../contexts/AppContext';
import CircleIconButton from './CircleIconButton';

interface Props {
  asMenuItem?: boolean
}

function InviteButton({ asMenuItem }: Props) {
  const { showAlert, smallScreen } = useApp()

  const clickHandler = useCallback(() => {
    window.navigator.clipboard.writeText("http://time.bubbleworkspace.com/").then(() => {
      showAlert({
        color: "success",
        message: "An invite link has been copied to the clipboard."
      })
    })
  }, [showAlert])

  if (asMenuItem)
    return (
      <MenuItem onClick={clickHandler}>
        <ListItemIcon><InviteIcon /></ListItemIcon>
        <ListItemText>Invite</ListItemText>
      </MenuItem>
    )

  if (smallScreen)
    return (
      <CircleIconButton onClick={clickHandler}>
        <InviteIcon />
      </CircleIconButton>
    )

  return (
    <Button
      startIcon={<InviteIcon />}
      onClick={clickHandler}
      sx={theme => ({
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.spacing(4),
        color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
        textTransform: "none",
        fontWeight: 600,
        px: 4,
        py: 2,
        mr: smallScreen ? 0 : 2,
        height: theme.spacing(8),
        lineHeight: theme.spacing(3)
      })}
    >
      Invite
    </Button>
  );
}

export default InviteButton;
