import React from 'react'
import { styled } from '@mui/material/styles';

const InviteIconSVG = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none" {...props}>
    <path d="M12.4001 9.60001V5.60001M10.4001 7.60001H14.4001" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.2001 13.6C11.2001 11.8362 9.75989 8.80002 6.55989 8.80002C2.77713 8.80002 1.6001 11.8362 1.6001 13.6" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 4.00001C4 5.32549 5.07452 6.40001 6.4 6.40001C7.72548 6.40001 8.8 5.32549 8.8 4.00001C8.8 2.67452 7.72548 1.60001 6.4 1.60001C5.07452 1.60001 4 2.67452 4 4.00001Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

const InviteIcon = styled(InviteIconSVG)(({ theme }) => {
  return {
    '& path': {
      stroke: theme.palette.mode === "dark" ? "white" : "black"
    }
  }
})

export default InviteIcon