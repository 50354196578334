import React from 'react';

//@mui
import { Box, Typography, AutocompleteRenderGroupParams } from '@mui/material';

interface Props {
  params: AutocompleteRenderGroupParams
}

function RenderGroup({ params }: Props) {
  return (
    <div key={params.key}>
      <Typography
        sx={theme => ({
          px: 2,
          py: 1,
          fontSize: "0.875rem",
          borderBottom: `1px solid ${theme.palette.grey[theme.palette.mode === "dark" ? 800 : 200]}`,
          color: theme.palette.grey[theme.palette.mode === "dark" ? 700 : 400]
        })}
      >{params.group}</Typography>
      <Box>{params.children}</Box>
    </div>
  );
}

export default RenderGroup