import React from 'react'
import { styled } from '@mui/material/styles';

interface Props {
  isGrey?: boolean
}

const CloseIconSVG = ({ isGrey, ...props }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none" {...props}>
    <path d="M10 9.48529L1.51472 1.00001" stroke="white" strokeWidth="1.875" strokeLinecap="round" />
    <path d="M1.51465 9.48529L9.99993 1.00001" stroke="white" strokeWidth="1.875" strokeLinecap="round" />
  </svg>
)

const CloseIcon = styled(CloseIconSVG)(({ theme, isGrey }) => {
  if (isGrey)
    return {
      '& path': {
        stroke: theme.palette.grey[theme.palette.mode === "dark" ? 500 : 500]
      }
    }

  return {
  }
})

export default CloseIcon
