import React, { createContext, useCallback, useContext, useEffect, useState, useMemo } from "react";
import fb, { NEW_GROUP_ID_PREFIX, NEW_GROUP_NAME } from "../firebase";
import { GoogleAuthProvider, User, getAdditionalUserInfo, signInWithPopup, sendSignInLinkToEmail } from "firebase/auth";
import { DBUser, Space } from "../types/Models";
import { helpers } from "../helpers";
import { DEFAULT_APP_FILTER_MODE, useApp } from "./AppContext";
import userContextHelper from "./userContextHelpers";

interface UserContextType {
  currentDBUser: DBUser | null
  currentGroup: Group | null
  currentUserOffset: number
  currentUserTime: Date
  currentUserTimezone: string
  groups: Group[]
  dbUsers: DBUser[]
  filteredDBUsers: DBUser[]
  initializing: boolean
  loadingUsers: boolean
  addNewGroup: () => void
  deleteGroup: (groupId: string) => Promise<void>
  loginWithGoogle: () => void
  sendSingInLinkToEmail: (email: string) => Promise<void>
  logout: () => void
  updateGroup: (group: Group) => Promise<void>
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { showAlert, reset, appFilter, setAppFilter, tick, customTimeOffsetMinutes } = useApp()
  const [currentFirebaseUser, setCurrentFirebaseUser] = useState<User | null>(null);
  const [dbUsers, setDbUsers] = useState<DBUser[]>([])
  const [initializing, setInitializing] = useState(true)
  const [loadingUsers, setLoadingUsers] = useState(true)
  const [groups, setGroups] = useState<Group[]>([])
  const [groupsAreInitiated, setGroupsAreInitiated] = useState(false)

  useEffect(() => {

    console.log("----loadingUsers----", loadingUsers)
  }, [loadingUsers])


  const currentGroup = useMemo(() => groups.find(c => c.id === appFilter.selectedGroupId) || null, [appFilter.selectedGroupId, groups])
  const currentDBUser = useMemo(() => dbUsers.find(u => u.email === currentFirebaseUser?.email) || null, [dbUsers, currentFirebaseUser?.email])

  const currentUserTimezone = useMemo(() => helpers.user.getTimezone(currentDBUser), [currentDBUser])
  const currentUserOffset = useMemo(() => helpers.user.getOffset(currentDBUser, new Date()), [currentDBUser])
  const currentUserTime = useMemo(() => helpers.getTimeByOffset(tick, currentUserOffset, customTimeOffsetMinutes), [currentUserOffset, customTimeOffsetMinutes, tick])

  const dbUsersWithIPLocation = useMemo(() => dbUsers.filter(u => Boolean(u.userIPLocationInfo)), [dbUsers])

  const filteredDBUsers = useMemo(() => {
    if (appFilter.mode === "favorites") {
      if (!currentDBUser?.favorites)
        return []

      return dbUsersWithIPLocation.filter(u => currentDBUser?.favorites?.includes(u.email))
    }

    if (appFilter.mode === "groups") {
      if (!currentGroup)
        return []

      return dbUsersWithIPLocation.filter(u => currentGroup.emails.includes(u.email))
    }

    //all-people and my-team
    //return all the users under my domain/space
    return dbUsersWithIPLocation
  }, [dbUsersWithIPLocation, appFilter, currentDBUser?.favorites, currentGroup])

  //on auth state change
  //set current user
  //check gmail.com domain
  //update ip and location
  useEffect(() => {
    console.log("User Context useEffect")

    const unsubscribe = fb.auth.onAuthStateChanged(async user => {
      const result = await userContextHelper.onAuthStateChanges(user, showAlert)

      setCurrentFirebaseUser(result)
      setInitializing(false)
      setLoadingUsers(!!result)

      if (!result) {
        fb.auth.signOut()
      }
    })

    return () => {
      unsubscribe && unsubscribe()
    }
  }, [showAlert])

  //setup the user presence
  useEffect(() => {
    if (!currentDBUser?.spaceId || !currentDBUser?.id)
      return

    console.log(`Setting up the presence for ${currentDBUser?.id} at ${currentDBUser?.spaceId}`)

    fb.presence.setup(currentDBUser?.spaceId, currentDBUser?.id)

  }, [currentDBUser?.spaceId, currentDBUser?.id])

  const logout = useCallback(() => {
    fb.auth.signOut()
    setDbUsers([])
    setGroups([])
    setCurrentFirebaseUser(null)
    reset()
  }, [reset])

  //login user
  //check gmail.com domain
  //create the space and user in the database
  const loginWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile');

    try {
      const result = await signInWithPopup(fb.auth, provider)
      const user = result.user
      const userEmailParts = (user.email || '').toLowerCase().split("@");

      let isValid = (() => {

        if (userEmailParts.length !== 2) {
          console.error("Invalid email format:", user.email);
          return false;
        }
        const domain = userEmailParts[1];

        if (domain === "gmail.com") {
          return false;
        }

        return true
      })()

      const additionalUserInfo = getAdditionalUserInfo(result)

      if (!user.email || !additionalUserInfo?.profile)
        isValid = false

      if (!isValid) {
        fb.auth.signOut()
        return
      }
      const profile: any = additionalUserInfo?.profile

      let dbUser: DBUser = {
        createdTime: fb.db.timestamp.now(),
        email: user.email || '',
        email_verified: user.emailVerified,
        family_name: profile["family_name"],
        given_name: profile["given_name"],
        hd: profile["hd"],
        id: userEmailParts[0],
        locale: profile["locale"],
        name: profile["name"],
        picture: profile["picture"],
        spaceId: profile["hd"],
        sub: profile["id"],
      }

      console.log("0- signInWithPopup dbUser", dbUser)

      const dbSpace: Space = {
        createdTime: fb.db.timestamp.now(),
        name: dbUser.spaceId,
        id: dbUser.spaceId,
        type: "google-workspace"
      }

      const ok = userContextHelper.createUserAndSpace(dbUser, dbSpace)

      if (!ok) {
        fb.auth.signOut()
        return null;
      }

      // This gives you a Google Access Token. You can use it to access the Google API.

      console.log("1- signInWithPopup result", result)
      const credential = GoogleAuthProvider.credentialFromResult(result);
      console.log("2- signInWithPopup credential", credential)
      console.log("getAdditionalUserInfo", getAdditionalUserInfo(result))
      // ...
    } catch (error: any) {
      console.log("3- signInWithPopup error", error)
      // Handle Errors here.
      const errorCode = error.code;
      console.log("3-1- signInWithPopup errorCode", errorCode)
      const errorMessage = error.message;
      console.log("3-2- signInWithPopup errorMessage", errorMessage)
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      console.log("3-3- signInWithPopup error email", email)
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.log("3-4- signInWithPopup error credential", credential)
      // ...
    }
  }, [])

  const sendSingInLinkToEmail = useCallback(async (email: string) => {
    await sendSignInLinkToEmail(fb.auth, email, {
      url: `${window.location.origin}/emailVerified`,
      handleCodeInApp: true,
    })
    window.localStorage.setItem('emailForSignIn', email);
  }, [])

  //listen to the list of users changes
  useEffect(() => {
    let unsubscribe = () => { };

    if (currentFirebaseUser?.email) {
      console.log("ADD ONCHANGE", currentFirebaseUser?.email)
      unsubscribe = fb.db.user.onChange(currentFirebaseUser.email, changes => {
        setLoadingUsers(false)
        setDbUsers(prev => {
          let result = [...prev]
          console.log("dbUsers", changes)
          changes.forEach(({ changeType, dbUser }) => {
            switch (changeType) {
              case "added":
                result.push(dbUser)
                break;
              case "modified":
                const foundIndex = result.findIndex(r => r.email === dbUser.email)
                if (foundIndex >= 0)
                  result[foundIndex] = {
                    ...result[foundIndex],
                    ...dbUser
                  }
                break;
              case "removed":
                result = [...result.filter(r => r.email !== dbUser.email)]
                break
            }
          })

          return result
        })
      })
    }

    return () => {
      unsubscribe()
    }
  }, [currentFirebaseUser?.email])

  //listen to the list of groups changes
  useEffect(() => {
    let unsubscribe = () => { };

    if (currentFirebaseUser?.email) {
      unsubscribe = fb.db.group.onChange(currentFirebaseUser.email, changes => {
        console.log("dbGroups", changes)
        setGroups(prev => {
          let result = [...prev]
          changes.forEach(({ changeType, group }) => {
            switch (changeType) {
              case "added":
                result.push(group)
                break;
              case "modified":
                const foundIndex = result.findIndex(r => r.id === group.id)
                if (foundIndex >= 0)
                  result[foundIndex] = {
                    ...result[foundIndex],
                    ...group
                  }
                break;
              case "removed":
                result = [...result.filter(r => r.id !== group.id)]
                break
            }
          })

          return result
        })

        setGroupsAreInitiated(true)
      })
    }

    return () => {
      unsubscribe()
    }
  }, [currentFirebaseUser?.email])

  //check if the group is not found by id
  useEffect(() => {
    if (!groupsAreInitiated)
      return

    if (appFilter.mode === "groups" && !currentGroup?.id)
      setAppFilter({ mode: DEFAULT_APP_FILTER_MODE })

    if (appFilter.mode === "all-people" && groups.length === 0)
      setAppFilter({ mode: DEFAULT_APP_FILTER_MODE })

  }, [groupsAreInitiated, groups, currentGroup, appFilter, setAppFilter])

  //delete untitled groups
  useEffect(() => {

    const groupsToDeleteIds = groups
      .filter(
        g => g.id !== appFilter.selectedGroupId && //the group is not selected
          g.id.startsWith(NEW_GROUP_ID_PREFIX) //it's a new group
      )
      .map(g => g.id)

    if (groupsToDeleteIds.length)
      setGroups(prev => [...prev.filter(p => !groupsToDeleteIds.includes(p.id))])

  }, [appFilter, groups])

  const addNewGroup = useCallback(() => {
    const newId = `${NEW_GROUP_ID_PREFIX}${Math.random().toString(36).replace(/[^a-z]+/g, '').substring(2)}`
    setGroups(prev => [...prev, {
      emails: [],
      name: NEW_GROUP_NAME,
      places: [],
      people: [],
      id: newId
    }])
    setAppFilter({
      mode: "groups",
      selectedGroupId: newId
    })
  }, [setAppFilter])

  const deleteGroup = useCallback(async (toDeleteGroupId: string) => {
    if (!toDeleteGroupId || !currentDBUser?.spaceId || !currentDBUser?.id)
      return

    if (toDeleteGroupId.startsWith(NEW_GROUP_ID_PREFIX)) {
      setGroups(prev => [...prev.filter(g => g.id !== toDeleteGroupId)])
      return
    }

    if (currentGroup?.id === toDeleteGroupId) {
      const otherGroups = groups.filter(g => g.id !== toDeleteGroupId)
      if (otherGroups.length === 0)
        setAppFilter({ mode: DEFAULT_APP_FILTER_MODE })
      else
        setAppFilter({ mode: "groups", selectedGroupId: otherGroups[0].id })
    }

    await fb.db.group.delete(currentDBUser?.spaceId, currentDBUser?.id, toDeleteGroupId)
  }, [currentGroup?.id, groups, currentDBUser?.spaceId, currentDBUser?.id, setAppFilter])

  const updateGroup = useCallback(async (group: Group) => {
    if (!currentDBUser?.spaceId || !currentDBUser?.id)
      return

    //create from the new group
    if (group.id.startsWith(NEW_GROUP_ID_PREFIX)) {
      const groupIdToRemove = group.id
      const newGroup = await fb.db.group.create(currentDBUser.spaceId, currentDBUser.id, group)
      if (newGroup) {
        setGroups(prev => [...prev.filter(g => g.id !== groupIdToRemove)])
        setAppFilter({ mode: "groups", selectedGroupId: newGroup.id })
      }
      return
    }
    else
      await fb.db.group.update(currentDBUser.spaceId, currentDBUser.id, group)
  }, [currentDBUser?.spaceId, currentDBUser?.id, setAppFilter])

  return (
    <UserContext.Provider value={{
      groups: groups,
      currentGroup: currentGroup,
      currentDBUser,
      currentUserOffset,
      currentUserTime,
      currentUserTimezone,
      dbUsers: dbUsersWithIPLocation,
      filteredDBUsers,
      initializing,
      loadingUsers,
      addNewGroup,
      deleteGroup,
      loginWithGoogle,
      sendSingInLinkToEmail,
      logout,
      updateGroup,
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
