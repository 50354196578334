import React from 'react'
import { styled } from '@mui/material/styles';

const AllPeopleIconSVG = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
  <path d="M6.10671 7.24671C6.04004 7.24004 5.96004 7.24004 5.88671 7.24671C4.30004 7.19337 3.04004 5.89337 3.04004 4.29337C3.04004 2.66004 4.36004 1.33337 6.00004 1.33337C7.63337 1.33337 8.96004 2.66004 8.96004 4.29337C8.95337 5.89337 7.69337 7.19337 6.10671 7.24671Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M10.9402 2.66663C12.2335 2.66663 13.2735 3.71329 13.2735 4.99996C13.2735 6.25996 12.2735 7.28663 11.0268 7.33329C10.9735 7.32663 10.9135 7.32663 10.8535 7.33329" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M2.77348 9.70663C1.16014 10.7866 1.16014 12.5466 2.77348 13.62C4.60681 14.8466 7.61348 14.8466 9.44681 13.62C11.0601 12.54 11.0601 10.78 9.44681 9.70663C7.62014 8.48663 4.61348 8.48663 2.77348 9.70663Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M12.2266 13.3334C12.7066 13.2334 13.1599 13.04 13.5332 12.7534C14.5732 11.9734 14.5732 10.6867 13.5332 9.90671C13.1666 9.62671 12.7199 9.44004 12.2466 9.33337" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
)

const AllPeopleIcon = styled(AllPeopleIconSVG)(({ theme }) => {
  return {
    '& path': {
      stroke: theme.palette.mode === "dark" ? "white" : "black"
    }
  }
})

export default AllPeopleIcon