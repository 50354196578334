import { Box, Typography, TypographyProps } from '@mui/material'
import React, { useMemo } from 'react'

interface Props extends TypographyProps {
  text: string
  search: string
}

interface TextPartType {
  text: string
  highlighted?: boolean
}

const SearchedText = ({ text, search, ...rest }: Props) => {

  const textParts = useMemo(() => {

    const strRegExp = new RegExp("(" + search + ")", "gi");
    const replacedText = text.replace(strRegExp, "|*|$1|*|")
    const parts = replacedText.split('|')

    let result: TextPartType[] = []
    let textFound = false

    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];

      if (part === '*') {
        textFound = !textFound
        continue
      }

      result.push({ text: part, highlighted: textFound })
    }

    return result
  }, [text, search])

  return <Typography {...rest}>
    {textParts.map((part, i) => (
      <Box
        key={i}
        component="span"
        bgcolor={theme => part.highlighted ? theme.palette.warning.light : undefined}
        // bgcolor={theme => part.highlighted ? theme.palette.grey[theme.palette.mode === "dark" ? 500 : 300] : undefined}
      >{part.text}</Box>
    ))}
  </Typography>
}

export default SearchedText