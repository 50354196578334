import React from 'react'
import { styled } from '@mui/material/styles';

interface Props {
  size: number
}

const LogoIconSVG = ({ size, ...props }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} style={{ minHeight: size }} viewBox="0 0 32 32" fill="none" {...props}>
    <g filter="url(#filter0_iii_233_732)">
      <path d="M0 14.3637C0 9.23061 0 6.66405 1.03059 4.7176C1.86227 3.14683 3.14683 1.86227 4.7176 1.03059C6.66405 0 9.23061 0 14.3637 0H17.6363C22.7694 0 25.336 0 27.2824 1.03059C28.8532 1.86227 30.1377 3.14683 30.9694 4.7176C32 6.66405 32 9.23061 32 14.3637V17.6363C32 22.7694 32 25.336 30.9694 27.2824C30.1377 28.8532 28.8532 30.1377 27.2824 30.9694C25.336 32 22.7694 32 17.6363 32H14.3637C9.23061 32 6.66405 32 4.7176 30.9694C3.14683 30.1377 1.86227 28.8532 1.03059 27.2824C0 25.336 0 22.7694 0 17.6363V14.3637Z" fill="#C4C4C4" />
      <path d="M0 14.3637C0 9.23061 0 6.66405 1.03059 4.7176C1.86227 3.14683 3.14683 1.86227 4.7176 1.03059C6.66405 0 9.23061 0 14.3637 0H17.6363C22.7694 0 25.336 0 27.2824 1.03059C28.8532 1.86227 30.1377 3.14683 30.9694 4.7176C32 6.66405 32 9.23061 32 14.3637V17.6363C32 22.7694 32 25.336 30.9694 27.2824C30.1377 28.8532 28.8532 30.1377 27.2824 30.9694C25.336 32 22.7694 32 17.6363 32H14.3637C9.23061 32 6.66405 32 4.7176 30.9694C3.14683 30.1377 1.86227 28.8532 1.03059 27.2824C0 25.336 0 22.7694 0 17.6363V14.3637Z" fill="url(#paint0_linear_233_732)" />
      <path d="M0 14.3637C0 9.23061 0 6.66405 1.03059 4.7176C1.86227 3.14683 3.14683 1.86227 4.7176 1.03059C6.66405 0 9.23061 0 14.3637 0H17.6363C22.7694 0 25.336 0 27.2824 1.03059C28.8532 1.86227 30.1377 3.14683 30.9694 4.7176C32 6.66405 32 9.23061 32 14.3637V17.6363C32 22.7694 32 25.336 30.9694 27.2824C30.1377 28.8532 28.8532 30.1377 27.2824 30.9694C25.336 32 22.7694 32 17.6363 32H14.3637C9.23061 32 6.66405 32 4.7176 30.9694C3.14683 30.1377 1.86227 28.8532 1.03059 27.2824C0 25.336 0 22.7694 0 17.6363V14.3637Z" fill="url(#paint1_linear_233_732)" />
    </g>
    <path d="M5.79643 17.8906C5.79643 14.7596 8.33466 12.2214 11.4657 12.2214C14.5968 12.2214 17.135 14.7596 17.135 17.8906C17.135 21.0217 14.5968 23.5599 11.4657 23.5599C8.33466 23.5599 5.79643 21.0217 5.79643 17.8906Z" fill="white" />
    <path d="M16.5036 10.3265C16.5036 7.82165 18.5342 5.79107 21.039 5.79107C23.5439 5.79107 25.5745 7.82165 25.5745 10.3265C25.5745 12.8314 23.5439 14.8619 21.039 14.8619C18.5342 14.8619 16.5036 12.8314 16.5036 10.3265Z" fill="white" />
    <path d="M20.1628 22.8684C20.1628 21.3724 21.3755 20.1597 22.8714 20.1597C24.3674 20.1597 25.5801 21.3724 25.5801 22.8684C25.5801 24.3643 24.3674 25.577 22.8714 25.577C21.3755 25.577 20.1628 24.3643 20.1628 22.8684Z" fill="white" />
    <defs>
      <filter id="filter0_iii_233_732" x="0" y="-4.94904" width="32" height="40.6255" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="3.67643" />
        <feGaussianBlur stdDeviation="3.39363" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.36 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_233_732" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="-4.94904" />
        <feGaussianBlur stdDeviation="3.39363" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.266667 0 0 0 0 0.302008 0 0 0 1 0" />
        <feBlend mode="normal" in2="effect1_innerShadow_233_732" result="effect2_innerShadow_233_732" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="-2.46094" />
        <feGaussianBlur stdDeviation="1.96875" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.909333 0 0 0 0 0.858333 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="effect2_innerShadow_233_732" result="effect3_innerShadow_233_732" />
      </filter>
      <linearGradient id="paint0_linear_233_732" x1="0" y1="0" x2="29.8453" y2="32.7253" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E8EFF4" />
        <stop offset="1" stopColor="#E6ECF0" />
      </linearGradient>
      <linearGradient id="paint1_linear_233_732" x1="30.422" y1="32.395" x2="5.52905" y2="-2.00612" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E81D2C" />
        <stop offset="0.53125" stopColor="#FF5C64" />
        <stop offset="1" stopColor="#FF8B91" />
      </linearGradient>
    </defs>
  </svg>
)

const LogoIcon = styled(LogoIconSVG)(({ theme }) => {
  return {
    // marginRight: theme.spacing(8),
  }
})

export default LogoIcon