import { useMemo } from 'react';
import { useUser } from '../../../contexts/UserContext';
import { useApp } from '../../../contexts/AppContext';
import { helpers } from '../../../helpers';
import AllTimeZoneBoxes from '../AllTimeZoneBoxes';
import { Box, Typography } from '@mui/material';
import SearchNewGroupItem from './SearchNewGroupItem/SearchNewGroupItem';
import _ from 'lodash';

const GroupView = () => {
  const { customTimeOffsetMinutes, tick, smallHeightScreen } = useApp()
  const { filteredDBUsers: dbUsers, currentGroup, currentUserTime } = useUser()

  const offsets = useMemo(() => {
    const groupOffsetInfos = helpers.offsetInfo.getGroupOffsetInfos(currentGroup?.places, currentGroup?.people, currentUserTime, tick, customTimeOffsetMinutes)
    const usersOffsetInfos = helpers.offsetInfo.getUsersOffsetInfos(dbUsers, currentUserTime, tick, customTimeOffsetMinutes)

    return _.chain([...groupOffsetInfos, ...usersOffsetInfos])
      .uniqBy(o => o.offset)
      .orderBy(o => o.offset)
      .value()
  }, [dbUsers, customTimeOffsetMinutes, tick, currentGroup?.people, currentGroup?.places, currentUserTime])

  return (
    <>
      <AllTimeZoneBoxes
        currentUserTime={currentUserTime}
        offsets={offsets}
      />

      {offsets.length === 0 &&
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginTop={smallHeightScreen ? 10 : 22}
        >
          <Typography variant='h5' sx={{ mb: 4 }}>Add your colleagues, cities and timezones</Typography>
          <Typography variant='caption' sx={{ mb: 4 }}>You can add your colleagues simply by their email or name, moreover you can also add a timezone or a city by simply search on the search bar</Typography>
          <SearchNewGroupItem large />
        </Box>
      }
    </>
  )
}

export default GroupView