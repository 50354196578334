import React, { Fragment, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import TimeZoneBox from './TimeZoneBox/TimeZoneBox';
import _ from 'lodash'
import { helpers } from '../../helpers';

interface Props {
  currentUserTime: Date
  offsets: OffsetInfo[]
}

const AllTimeZoneBoxes = ({ currentUserTime, offsets }: Props) => {

  const currentUserDay = useMemo(() => helpers.getDateString(currentUserTime), [currentUserTime])
  const todayOffsets = useMemo(() => offsets.filter(o => o.day === currentUserDay), [currentUserDay, offsets])
  const anotherDayOffsets = useMemo(() => offsets.filter(o => o.day !== currentUserDay), [currentUserDay, offsets])

  return (
    <>

      {todayOffsets
        .map((offset, i) => (
          <TimeZoneBox
            key={i}
            offset={offset.offset}
            time={offset.time}
            name={offset.name}
          />
        ))}


      {anotherDayOffsets.length > 0 &&
        _.chain(anotherDayOffsets)
          .map(o => o.day)
          .uniq()
          .value()
          .map(day => (
            <Fragment key={day}>
              <Box display='flex' gap="12px" alignItems="center" justifyContent='center' paddingTop={4}>
                <Box
                  sx={theme => ({
                    height: "1px",
                    flexGrow: 1,
                    borderTop: `1px dashed ${theme.palette.grey[theme.palette.mode === "dark" ? 700 : 400]}`
                  })}
                />
                <Typography fontWeight={600}>{day}</Typography>
                <Box
                  sx={theme => ({
                    height: "1px",
                    flexGrow: 1,
                    borderTop: `1px dashed ${theme.palette.grey[theme.palette.mode === "dark" ? 700 : 400]}`
                  })}
                />
              </Box>

              {offsets
                .filter(o => o.day === day)
                .map((offset, i) => (
                  <TimeZoneBox
                    key={i}
                    offset={offset.offset}
                    time={offset.time}
                    name={offset.name}
                  />
                ))}
            </Fragment>
          ))
      }
    </>
  )
}

export default AllTimeZoneBoxes