
import React, { useCallback, useMemo } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Typography, Slider } from '@mui/material';
import { useApp } from '../../../contexts/AppContext';

interface Mark {
  value: number
  label?: React.ReactNode
}

const CustomSlider = styled(Slider)(({ theme }) => {
  const { smallScreen } = useApp()
  const p = theme.palette

  return ({
    height: 0,
    padding: '15px 0',
    marginTop: 12,
    '& .MuiSlider-thumb': {
      height: 60,
      width: 2,
      borderRadius: 0,
      top: 20,
      background: `linear-gradient(${p.text.primary}, ${p.text.primary}, ${p.text.primary}, ${p.background.default})`,
      '&:before': {
        position: 'absolute',
        display: "inline-block",
        width: "12px",
        height: "12px",
        left: "-5px",
        top: "0px",
        borderRadius: "50%",
        backgroundColor: p.text.primary
      },
      '&:hover, &.Mui-active': {
        boxShadow: `0px 0px 7px 1px ${alpha(p.text.primary, 0.5)}`,
      },
      '&:focus, &.Mui-focusVisible': {
        boxShadow: `0px 0px 7px 1px ${alpha(p.text.primary, 0.3)}`,
      },
    },
    '& .MuiSlider-markLabel': {
      top: smallScreen ? undefined : 20,
      '& p': {
        fontSize: 14,
        fontWeight: 600,
        color: alpha(theme.palette.text.primary, 0.25),
      }
    },
    '& .MuiSlider-valueLabel': {
      fontSize: 12,
      fontWeight: 'normal',
      top: -6,
      backgroundColor: 'unset',
      color: theme.palette.text.primary,
      '&:before': {
        display: 'none',
      },
      '& *': {
        background: 'transparent',
        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
      },
    },
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-rail': {
      opacity: 0,
    },
    '& .MuiSlider-mark': {
      backgroundColor: p.grey[500],
      height: 8,
      width: 1,
      // '&.MuiSlider-markActive': {
      //   opacity: 1,
      //   backgroundColor: '#aaa',
      // },
    },
  })
})

const TimeSlider: React.FC = () => {
  const { timeFormat, customTimeOffsetMinutes, tick, setCustomTimeOffsetMinutes, smallScreen } = useApp()

  const marks = useMemo(() => {
    let result: Mark[] = []
    for (let min = 0; min <= 1440; min++) {
      const hour = min % 60 === 0 ? (min / 60) : undefined
      let label = hour !== undefined ? (timeFormat === 24 ? hour : hour % timeFormat) : null

      if (label === 0 && timeFormat === 12)
        label = 12

      if (min % (smallScreen ? 30 : 10) === 0)
        result.push({
          value: min,
          label: min % (smallScreen ? 240 : 120) === 0 ? <Typography>{label}</Typography> : null
        })
    }

    return result
  }, [timeFormat, smallScreen])

  const selectedTimeMinutes = useMemo(() => {
    const now = new Date(tick)
    let nowMinutes = now.getHours() * 60 + now.getMinutes()

    if (customTimeOffsetMinutes)
      nowMinutes += customTimeOffsetMinutes

    return nowMinutes
  }, [customTimeOffsetMinutes, tick])

  const handleChange = useCallback((event: Event, newValue: number | number[]) => {
    const now = new Date(tick)
    let nowMinutes = now.getHours() * 60 + now.getMinutes()

    setCustomTimeOffsetMinutes(newValue as number - nowMinutes)
  }, [tick, setCustomTimeOffsetMinutes])

  return (
    <CustomSlider
      marks={marks}
      step={1}
      min={0}
      max={1440}
      value={selectedTimeMinutes}
      onChange={handleChange}
    />
  );
}

export default TimeSlider;
