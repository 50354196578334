
import React from 'react';
import { Paper, Box, Typography } from '@mui/material';
import ShowTime from './ShowTime';
import TimeSlider from './TimeSlider';
import ResetButton from './ResetButton';
import { useApp } from '../../../contexts/AppContext';
import { helpers } from '../../../helpers';
import { useUser } from '../../../contexts/UserContext';

const TimeZoneBoxSlider = () => {
  const { smallScreen } = useApp()
  const { currentUserTime, currentUserTimezone, currentUserOffset } = useUser()

  return (
    <Paper elevation={0} sx={{ p: smallScreen ? 4 : 6, mt: 4, pb: 0 }}>
      <Box display="flex">
        <Box flexGrow={1}>
          {smallScreen &&
            <ShowTime time={currentUserTime} />
          }

          <Box
            display={smallScreen ? "flex" : undefined}
            paddingTop={smallScreen ? 3 : 0}
            sx={smallScreen ? {
              '& p': {
                fontSize: "0.875rem !important",
                lineHeight: "24px"
              }
            } : {}}
          >

            {/* ----------Time Zone Name----------- */}
            <Box display="flex" alignItems="center">
              <Typography fontWeight={600}>{helpers.getTimezoneAbbr(currentUserTimezone, currentUserOffset, currentUserTime)}</Typography>
              <Typography
                sx={theme => ({
                  color: theme.palette.grey[500],
                  marginLeft: 1
                })}
              >{`(${helpers.getUTCOffsetTitle(currentUserOffset)})${smallScreen ? ", " : ""}`}
              </Typography>
            </Box>
            {/* ----------Time Zone Name----------- */}
            <Typography
              fontWeight={600}
              sx={theme => ({ color: theme.palette.grey[500] })}
            >{helpers.getDateString(currentUserTime)}</Typography>

          </Box>

        </Box>
        <ResetButton />

        {!smallScreen &&
          <ShowTime time={currentUserTime} />
        }
      </Box>

      <TimeSlider />
    </Paper >
  )
}

export default TimeZoneBoxSlider;