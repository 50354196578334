
import React from 'react'
import { styled } from '@mui/material/styles';

const MenuIconSVG = (props: any) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 1L1.00027 1" stroke="white" strokeWidth="1.875" strokeLinecap="round" />
    <path d="M13 7L1 7" stroke="white" strokeWidth="1.875" strokeLinecap="round" />
    <path d="M13 13L1.00027 13" stroke="white" strokeWidth="1.875" strokeLinecap="round" />
  </svg>
)

const MenuIcon = styled(MenuIconSVG)(({ theme }) => {
  return {
  }
})

export default MenuIcon
