import React from 'react';

//@mui
import TextField from '@mui/material/TextField';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';

interface Props {
  params: AutocompleteRenderInputParams
}

function RenderInput({ params }: Props) {

  return (
    <form noValidate>
      <TextField
        {...params}
        variant='outlined'
        placeholder='Add colleagues, cities or timezones'
        autoComplete='off'
        InputProps={{
          ...params.InputProps,
          autoComplete: "off",
          sx: theme => ({
            backgroundColor: theme.palette.background.default,
            // borderRadius: 16,
            px: 2,
            py: "4px !important",
            '& input': {
              padding: 0,
              fontSize: "0.875rem"
            },
            '& fieldset': {
              border: 'none',
            }
          })
        }}
      />
    </form>
  );
}

export default RenderInput