import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Autocomplete } from '@mui/material';

import { useApp } from '../../../../contexts/AppContext';
import { useUser } from '../../../../contexts/UserContext';
import { helpers } from '../../../../helpers';
import _ from 'lodash';
import { DBUser } from '../../../../types/Models';
import RenderOption from './RenderOption';
import RenderGroup from './RenderGroup';
import RenderInput from './RenderInput';
import ArrowDownIcon from '../../../Icons/ArrowDownIcon';
import IconContainer from '../../../common/IconContainer';

interface Props {
  large?: boolean
}

export type OptionType = { label: string, type: "Users", data: DBUser } |
{ label: string, type: "Timezones" } |
{ label: string, type: "Cities", data: google.maps.places.AutocompletePrediction }

function SearchNewGroupItem({ large }: Props) {
  const { smallScreen } = useApp()
  const { dbUsers, currentGroup, currentUserTime, updateGroup } = useUser()
  const [text, setText] = useState('')
  const [value, setValue] = useState<OptionType | null>(null)
  const [cities, setCities] = useState<google.maps.places.AutocompletePrediction[]>([])

  const timezones = useMemo(() => helpers.getAllTimezones(), [])

  const options = useMemo(() => {
    let res = _.chain(dbUsers)
      .map(u => ({
        label: `${helpers.user.getName(u)}, ${u.email}`,
        type: 'Users',
        data: u
      } as OptionType))
      .orderBy(u => u.label)
      .value()

    res.push(...cities.map(c => ({
      label: c.description,
      type: "Cities",
      data: c
    } as OptionType)))

    res.push(..._.chain(timezones)
      .map(t => ({
        label: t,
        type: "Timezones"
      } as OptionType))
      .value())

    return res
  }, [timezones, dbUsers, cities])

  //load search for cities based on text
  useEffect(() => {
    let handle = setTimeout(async () => {
      if (!text)
        return

      try {
        console.log("calling predictions", text)
        const predictions = await helpers.googleApis.cities(text)

        console.log("predictions", predictions)

        if (predictions) {
          setCities(predictions)
          return
        }
      } catch (error) {
        console.log("predictions error", error)
      }

      setCities([])

    }, 300);

    return () => {
      clearTimeout(handle)
    }
  }, [text])


  const handleSelect = useCallback(async (option: OptionType | null) => {
    // setValue(option)

    if (!currentGroup || !option)
      return

    let newGroup = { ...currentGroup }
    let shouldUpdate = false

    if (option.type === "Timezones" && (newGroup.places || []).findIndex(p => p.timezone === option.label && p.name === option.label) < 0) {
      shouldUpdate = true
      newGroup.places = [...(newGroup.places || []), {
        name: option.label,
        timezone: option.label
      }]
    }

    if (option.type === "Users" && !(newGroup.emails || []).includes(option.data.email)) {
      shouldUpdate = true
      newGroup.emails = [...(newGroup.emails || []), option.data.email]
    }

    if (option.type === "Cities") {
      //get the selected city's timezone
      const { timezone, country } = await helpers.googleApis.getCityTimezoneByPlaceId(option.data.place_id)
      const name = option.data.structured_formatting.main_text
      if ((newGroup.places || []).findIndex(p => p.timezone === timezone && p.name === name) < 0) {
        shouldUpdate = true
        newGroup.places = [...(newGroup.places || []), {
          name,
          timezone,
          country_long_name: country?.long_name,
          country_short_name: country?.short_name
        }]
      }
    }

    if (shouldUpdate) {
      await updateGroup(newGroup)
    }
    setText('')
    setValue(null)

  }, [currentGroup, updateGroup])

  return (
    <Box
      sx={{
        ...(large ? {
          maxWidth: smallScreen ? "100%" : "70%",
          width: "500px"
        } : {
          width: smallScreen ? "100%" : "340px",
          pt: 3
        })
      }}
    >
      <Autocomplete
        options={options}
        groupBy={option => option.type}
        fullWidth

        isOptionEqualToValue={(option, value) => option.label === value.label}

        inputValue={text}
        onInputChange={(e, newText) => setText(newText)}

        value={value}
        onChange={(e, value) => handleSelect(value)}

        popupIcon={<IconContainer><ArrowDownIcon /></IconContainer>}
        renderOption={(props, option) => <RenderOption currentUserTime={currentUserTime} props={props} option={option} {...props} />}
        renderGroup={params => <RenderGroup params={params} {...params} />}
        renderInput={params => <RenderInput params={params} />}
      />

    </Box>
  );
}

export default SearchNewGroupItem