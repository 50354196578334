import React from 'react'
import { styled } from '@mui/material/styles';

const ArrowUpIconSVG = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.70711 5.70711C9.31658 6.09763 8.68342 6.09763 8.29289 5.70711L5 2.41421L1.70711 5.70711C1.31658 6.09763 0.683418 6.09763 0.292894 5.70711C-0.0976312 5.31658 -0.0976312 4.68342 0.292894 4.29289L4.29289 0.292893C4.68342 -0.0976324 5.31658 -0.0976319 5.70711 0.292893L9.70711 4.29289C10.0976 4.68342 10.0976 5.31658 9.70711 5.70711Z" fill="black" />
  </svg>
)

const ArrowUpIcon = styled(ArrowUpIconSVG)(({ theme }) => {
  return {
    '& path': {
      fill: theme.palette.mode === "dark" ? "white" : "black"
    }
  }
})

export default ArrowUpIcon