import React from 'react'
import { styled } from '@mui/material/styles';

const GroupIconSVG = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none" {...props}>
    <path d="M1.66663 14.6667H16.3333" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.1866 14.6733V11.7" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.2 7.26001C13.3866 7.26001 12.7333 7.91335 12.7333 8.72668V10.24C12.7333 11.0533 13.3866 11.7067 14.2 11.7067C15.0133 11.7067 15.6666 11.0533 15.6666 10.24V8.72668C15.6666 7.91335 15.0133 7.26001 14.2 7.26001Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.40002 14.6666V4.01994C2.40002 2.67994 3.06673 2.00659 4.39339 2.00659H8.54671C9.87338 2.00659 10.5334 2.67994 10.5334 4.01994V14.6666" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.8667 5.5H8.16671" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.8667 8H8.16671" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.5 14.6667V12.1667" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

const GroupIcon = styled(GroupIconSVG)(({ theme }) => {
  return {
    '& path': {
      stroke: theme.palette.mode === "dark" ? "white" : "black"
    }
  }
})

export default GroupIcon