import React from 'react'
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';

const SearchIconSVG = (props: any) => (
  <Box {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <circle cx="7" cy="7" r="6" stroke="#CCCCCC" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M12.5303 11.4697L12 10.9393L10.9393 12L11.4697 12.5303L12.5303 11.4697ZM14.4697 15.5303C14.7626 15.8232 15.2374 15.8232 15.5303 15.5303C15.8232 15.2374 15.8232 14.7626 15.5303 14.4697L14.4697 15.5303ZM11.4697 12.5303L14.4697 15.5303L15.5303 14.4697L12.5303 11.4697L11.4697 12.5303Z" fill="#CCCCCC" />
    </svg>
  </Box>
)

const SearchIcon = styled(SearchIconSVG)(({ theme }) => {
  return {
    paddingLeft: theme.spacing(2.5),
    borderLeft: `1px solid ${alpha(theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black, 0.1)}`,
    height: 20,
  }
})

export default SearchIcon