import React from 'react';

//@mui
import Button from '@mui/material/Button';

import ResetIcon from '../../Icons/ResetIcon';
import { useApp } from '../../../contexts/AppContext';

function ResetButton() {
  const { customTimeOffsetMinutes, setCustomTimeOffsetMinutes, smallScreen } = useApp()

  if (customTimeOffsetMinutes === null)
    return null

  return (
    <Button
      startIcon={<ResetIcon />}
      onClick={() => setCustomTimeOffsetMinutes(null)}
      sx={theme => ({
        borderRadius: theme.spacing(4),
        backgroundColor: theme.palette.grey[theme.palette.mode === "dark" ? 800 : 100],
        color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
        textTransform: "none",
        fontWeight: 600,
        px: 4,
        py: 2,
        mr: smallScreen ? 0 : 2,
        height: theme.spacing(8),
        lineHeight: theme.spacing(3)
      })}
    >
      Reset
    </Button>
  );
}

export default ResetButton