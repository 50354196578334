import React from 'react'
import { styled } from '@mui/material/styles';

const ArrowRightIconSVG = (props: any) => (
  <svg viewBox="0 0 24 24" width={12} height={12} fill="none" {...props}>
    <path d="M14.29 5.71c-.39.39-.39 1.02 0 1.41L18.17 11H3c-.55 0-1 .45-1 1s.45 1 1 1h15.18l-3.88 3.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l5.59-5.59c.39-.39.39-1.02 0-1.41l-5.6-5.58c-.38-.39-1.02-.39-1.41 0"></path>
  </svg>
)

const ArrowRightIcon = styled(ArrowRightIconSVG)(({ theme }) => {
  return {
    '& path': {
      fill: theme.palette.mode === "dark" ? "white" : "black"
    }
  }
})

export default ArrowRightIcon