import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import LogoIcon from '../Icons/LogoIcon';
import { useApp } from '../../contexts/AppContext';

interface Props {
  children: React.ReactNode
}

const LoginLayout: React.FC<Props> = ({ children }) => {
  const { smallScreen } = useApp()
  const theme = useTheme()

  const bubbleColor = theme.palette.mode === "dark" ? "white" : "black"

  return (
    <>
      <Box
        display="flex"
        justifyContent={smallScreen ? "flex-end" : "space-between"}
        alignItems={smallScreen ? "flex-end" : "center"}
        width='100vw'
        height='100vh'
        position='absolute'
        overflow="hidden"
        zIndex={-1}
      >
        {!smallScreen &&
          <img
            src="/login-bg-left.svg"
            alt="world - america"
            style={{
              maxHeight: "75vh",
              maxWidth: smallScreen ? "45vw" : "30vw"
            }}
          />
        }
        <img
          src="/login-bg-right.svg"
          alt="world - europe asia"
          style={{
            maxHeight: smallScreen ? "50vh" : "75vh",
            maxWidth: smallScreen ? "70vw" : "30vw",
            right: "-20vw",
            position: smallScreen ? 'absolute' : undefined
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent={smallScreen ? undefined : "center"}
        alignItems="center"
        flexDirection="column"
        height="100vh"
        width='100vw'
        sx={{
          p: 10,
        }}
      >
        <LogoIcon size={48} />
        <Box
          flexGrow={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          {children}
        </Box>
        <Box
          sx={theme => ({
            '@media (max-height:450px)': {
              display: 'none'
            }
          })}
        >
          <Typography textAlign="center" variant='caption' component="div" sx={{ mb: 2.5 }}>Powered by</Typography>
          <svg xmlns="http://www.w3.org/2000/svg" width="91" height="18" viewBox="0 0 91 18" fill="none">
            <path d="M89.6503 12.4357C90.5048 12.4357 90.8879 11.9678 90.8879 11.3829C90.8879 8.78034 89.061 6.58713 85.8199 6.58713C82.4019 6.58713 80.6045 9.10201 80.6045 11.9093C80.6045 14.6289 82.284 17.1145 86.2324 17.1145C87.8235 17.1145 89.2968 16.7051 90.2102 16.091C90.5343 15.8863 90.6816 15.5939 90.6816 15.3015C90.6816 14.6874 90.0039 14.1318 89.2378 14.5119C88.5012 14.9506 87.6762 15.3015 86.4681 15.3015C84.4055 15.3015 83.1974 14.044 83.109 12.4357H89.6503ZM83.1385 10.8566C83.2564 9.59914 84.1403 8.37094 85.8788 8.37094C87.5878 8.37094 88.5012 9.59914 88.6191 10.8566H83.1385Z" fill={bubbleColor} />
            <path d="M75.7793 14.0737C75.7793 15.8283 76.3391 17.115 78.3722 17.115C79.1973 17.115 79.5803 16.8518 79.5803 16.15C79.5803 15.4189 79.3151 15.2142 78.9616 15.1849C78.2249 15.1265 78.1365 14.6293 78.1365 13.7521V2.11342C78.1365 1.26538 77.5767 0.885223 76.9284 0.885223C76.3097 0.885223 75.7793 1.26538 75.7793 2.11342V14.0737Z" fill={bubbleColor} />
            <path d="M63.0371 15.9452C63.0371 16.5885 63.3907 17.1149 64.2157 17.1149C64.8934 17.1149 65.3354 16.764 65.3354 15.9452L65.2765 15.0094C65.9836 16.3254 67.1033 17.1149 68.8418 17.1149C72.024 17.1149 73.7036 14.6293 73.7036 11.7927C73.7036 8.98542 72.024 6.58751 68.8712 6.58751C67.2801 6.58751 66.1015 7.28933 65.3943 8.51753V2.05487C65.3943 1.29456 64.9524 0.885162 64.2157 0.885162C63.4791 0.885162 63.0371 1.29456 63.0371 2.05487V15.9452ZM68.3409 15.0387C66.4551 15.0387 65.3943 13.5473 65.3943 11.822C65.3943 10.0966 66.4551 8.66374 68.3409 8.66374C70.1972 8.66374 71.2874 10.0674 71.2874 11.8512C71.2874 13.635 70.1972 15.0387 68.3409 15.0387Z" fill={bubbleColor} />
            <path d="M50.0596 15.9452C50.0596 16.5885 50.4132 17.1149 51.2382 17.1149C51.9159 17.1149 52.3579 16.764 52.3579 15.9452L52.2989 15.0094C53.0061 16.3254 54.1258 17.1149 55.8642 17.1149C59.0465 17.1149 60.726 14.6293 60.726 11.7927C60.726 8.98542 59.0465 6.58751 55.8937 6.58751C54.3026 6.58751 53.124 7.28933 52.4168 8.51753V2.05487C52.4168 1.29456 51.9748 0.885162 51.2382 0.885162C50.5016 0.885162 50.0596 1.29456 50.0596 2.05487V15.9452ZM55.3633 15.0387C53.4775 15.0387 52.4168 13.5473 52.4168 11.822C52.4168 10.0966 53.4775 8.66374 55.3633 8.66374C57.2196 8.66374 58.3099 10.0674 58.3099 11.8512C58.3099 13.635 57.2196 15.0387 55.3633 15.0387Z" fill={bubbleColor} />
            <path d="M40.24 7.75684C40.24 7.08426 39.8865 6.58713 39.0909 6.58713C38.2659 6.58713 37.8828 7.08426 37.8828 7.75684V12.2895C37.8828 15.1553 39.032 17.1145 41.8312 17.1145C42.9508 17.1145 44.1589 16.6174 44.8661 15.6232V15.9448C44.8661 16.7344 45.2491 17.1145 45.9858 17.1145C46.7519 17.1145 47.2233 16.6759 47.2233 15.9448V7.75684C47.2233 6.96728 46.7813 6.58713 46.0447 6.58713C45.2786 6.58713 44.8661 6.96728 44.8661 7.75684V12.348C44.8661 14.2487 43.9232 15.1845 42.5089 15.1845C41.0356 15.1845 40.24 14.3072 40.24 12.348V7.75684Z" fill={bubbleColor} />
            <path d="M25.083 15.9452C25.083 16.5885 25.4366 17.1149 26.2616 17.1149C26.9393 17.1149 27.3813 16.764 27.3813 15.9452C27.3813 15.1264 27.3224 15.0094 27.3224 15.0094C28.0295 16.3254 29.1492 17.1149 30.8877 17.1149C34.0699 17.1149 35.7495 14.6293 35.7495 11.7927C35.7495 8.98542 34.0699 6.58751 30.9171 6.58751C29.326 6.58751 28.1474 7.28933 27.4402 8.51753V2.05487C27.4402 1.29456 26.9983 0.885162 26.2616 0.885162C25.525 0.885162 25.083 1.29456 25.083 2.05487V15.9452ZM30.3868 15.0387C28.501 15.0387 27.4402 13.5473 27.4402 11.822C27.4402 10.0966 28.501 8.66374 30.3868 8.66374C32.2431 8.66374 33.3333 10.0674 33.3333 11.8512C33.3333 13.635 32.2431 15.0387 30.3868 15.0387Z" fill={bubbleColor} />
            <path d="M1.38517e-05 10.9555C1.36013e-05 8.09105 2.3221 5.76896 5.18653 5.76896C8.05096 5.76896 10.373 8.09105 10.373 10.9555C10.373 13.8199 8.05096 16.142 5.18653 16.142C2.3221 16.142 1.41021e-05 13.8199 1.38517e-05 10.9555Z" fill="#FF424B" />
            <path d="M9.76259 4.11869C9.76259 1.844 11.6066 -8.97781e-06 13.8813 -9.17667e-06C16.156 -9.37554e-06 18 1.844 18 4.11869C18 6.39339 16.156 8.2374 13.8813 8.2374C11.6066 8.2374 9.76259 6.39339 9.76259 4.11869Z" fill="#FF424B" />
            <path d="M13.1186 15.5593C13.1186 14.2113 14.2113 13.1185 15.5593 13.1185C16.9073 13.1185 18 14.2113 18 15.5593C18 16.9072 16.9073 18 15.5593 18C14.2113 18 13.1186 16.9072 13.1186 15.5593Z" fill="#FF424B" />
          </svg>
        </Box>
      </Box>
    </>
  );
}

export default LoginLayout;
