import { Avatar, Box, Button, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import React, { useCallback, useMemo, useState } from 'react'
import SimpleBar from 'simplebar-react'
import CloseIcon from '../../Icons/CloseIcon'
import { useApp } from '../../../contexts/AppContext'
import LogoIcon from '../../Icons/LogoIcon'
import { useUser } from '../../../contexts/UserContext'
import LocationIcon from '../../Icons/LocationIcon'
import InfoIcon from '../../Icons/InfoIcon'
import EmailIcon from '../../Icons/EmailIcon'
import CustomLocation from './CustomLocation'
import ReportingManager from './ReportingManager'
import { DBUser } from '../../../types/Models'
import { helpers } from '../../../helpers'
import fb from '../../../firebase'

const EditProfile: React.FC = () => {
  const { setEditProfileIsOpen, smallScreen } = useApp()
  const { currentDBUser, dbUsers } = useUser()
  const padding = smallScreen ? "16px" : "32px"
  const gutterBottom = 5

  const [firstName, setFirstName] = useState(currentDBUser?.customProfile?.firstName || currentDBUser?.given_name || '')
  const [lastName, setLastName] = useState(currentDBUser?.customProfile?.lastName || currentDBUser?.family_name || '')
  const [role, setRole] = useState(currentDBUser?.customProfile?.role || '')
  const [city, setCity] = useState<google.maps.places.AutocompletePrediction | null>(null)
  const foundReportingManager = useMemo(() => dbUsers.find(u => u.email === currentDBUser?.customProfile?.reportingManagerEmail) || null, [currentDBUser?.customProfile?.reportingManagerEmail, dbUsers])
  const [reportingManager, setReportingManager] = useState<DBUser | null>(foundReportingManager)

  const close = useCallback(() => setEditProfileIsOpen(false), [setEditProfileIsOpen])

  const save = useCallback(async () => {
    if (!currentDBUser)
      return

    let customProfile: UserCustomProfile = {}

    if (firstName !== currentDBUser.given_name)
      customProfile.firstName = firstName

    if (lastName !== currentDBUser.family_name)
      customProfile.lastName = lastName

    if (role)
      customProfile.role = role

    if (reportingManager?.email)
      customProfile.reportingManagerEmail = reportingManager.email

    //if a google prediction is selected
    if (city) {
      //if the location hasn't been changed since last time
      if (currentDBUser.customProfile?.location?.placeId === city.place_id) {
        customProfile.location = currentDBUser.customProfile.location
      }
      else {
        //get the selected city's timezone
        const { timezone, province, country } = await helpers.googleApis.getCityTimezoneByPlaceId(city.place_id)
        const cityName = city.structured_formatting.main_text
        
        customProfile.location = {
          placeId: city.place_id,
          cityName,
          provinceShortName: province?.short_name || '',
          provinceLongName: province?.long_name || '',
          countryLongName: country?.long_name || '',
          countryShortName: country?.short_name || '',
          timezone
        }
      }
    }

    await fb.db.user.updateCustomerProfile(currentDBUser.spaceId, currentDBUser.id, customProfile)
    close()

  }, [city, role, lastName, firstName, currentDBUser, reportingManager?.email, close])

  if (!currentDBUser)
    return null

  return (
    <Box
      sx={theme => ({
        pt: padding,
        height: '100vh',
        bgcolor: theme.palette.grey[theme.palette.mode === "dark" ? 900 : 200],
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      })}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{
          px: padding
        }}
      >
        <LogoIcon size={36} />
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography fontWeight={600}>User Profile</Typography>
          {!smallScreen &&
            <Typography variant='caption'>Maintain all of your account settings in one place</Typography>
          }
        </Box>
        <IconButton onClick={close}>
          <CloseIcon isGrey />
        </IconButton>
      </Box>
      <SimpleBar
        style={{
          flexGrow: 1,
          minHeight: 0,
          padding: padding,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            width={smallScreen ? "100%" : "540px"}
          >

            <Box display="flex" justifyContent="center" alignItems="center" marginBottom={10}>
              <Avatar
                sx={{
                  width: smallScreen ? 64 : 104,
                  height: smallScreen ? 64 : 104
                }}
                src={currentDBUser.picture}
              />
              <Box paddingLeft={4}>
                <Typography fontWeight={600}>{firstName} {lastName}</Typography>
                <Box display="flex" gap={1} alignItems="center">
                  <EmailIcon />
                  <Typography variant='caption'>{currentDBUser?.email}</Typography>
                </Box>
                <Tooltip title="This location is based on your IP.">
                  <Box display="flex" gap={1} alignItems="center">
                    <LocationIcon />
                    <Typography variant='caption'>{`${currentDBUser?.userIPLocationInfo?.city}, ${currentDBUser?.userIPLocationInfo?.country_name}`}</Typography>
                    <InfoIcon />
                  </Box>
                </Tooltip>
              </Box>
            </Box>

            <Box sx={{ mb: gutterBottom }}>
              <Typography fontWeight={600}>Name *</Typography>
              <Box display="flex" gap={2}>
                <TextField
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  placeholder='First Name'
                  size="small"
                  sx={{ flexGrow: 1 }}
                />
                <TextField
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  placeholder='Last Name'
                  size="small"
                  sx={{ flexGrow: 1 }}
                />
              </Box>
            </Box>

            <Box sx={{ mb: gutterBottom }}>
              <Typography fontWeight={600}>Custom Location</Typography>
              <CustomLocation
                initLocation={currentDBUser?.customProfile?.location}
                onSelect={setCity}
              />
            </Box>

            <Box sx={{ mb: gutterBottom }}>
              <Typography fontWeight={600}>Role/Title *</Typography>
              <TextField
                value={role}
                onChange={e => setRole(e.target.value)}
                placeholder='Ex. Project Manager'
                size="small"
                fullWidth
              />
            </Box>

            <Box sx={{ mb: gutterBottom }}>
              <Typography fontWeight={600}>Reporting Manager</Typography>
              <ReportingManager
                initUser={reportingManager}
                onSelect={setReportingManager}
              />
            </Box>

            <Box display="flex" justifyContent="center" gap={2.5}>
              <Button
                variant='outlined'
                onClick={close}
              >Cancel</Button>
              <Button
                variant='contained'
                onClick={save}
              >Save</Button>
            </Box>

          </Box>
        </Box>
      </SimpleBar>
    </Box>
  )
}

export default EditProfile