
import React, { useCallback, useMemo } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import CloseIcon from '../../Icons/CloseIcon';
import { useUser } from '../../../contexts/UserContext';
import IconContainer from '../../common/IconContainer';
import { helpers } from '../../../helpers';
import SearchedText from '../../common/SearchedText';
import { useApp } from '../../../contexts/AppContext';

interface Props {
  place: GroupPlace
}

const PlaceItem: React.FC<Props> = ({ place }) => {
  const { currentGroup, updateGroup } = useUser()
  const { search } = useApp()
  const isCity = useMemo(() => place.name !== place.timezone, [place])
  const title = useMemo(() => isCity ? `${place.name}, ${place.country_long_name}` : place.name, [isCity, place])

  const flag = useMemo(() => isCity && place.country_short_name ? helpers.getFlagByCountryShortName(place.country_short_name) : null, [isCity, place.country_short_name])

  const handleDelete = useCallback(async () => {
    if (!currentGroup)
      return

    let newGroup = { ...currentGroup }

    if (newGroup.places.some(p => p.name === place.name)) {
      newGroup.places = newGroup.places.filter(p => p.name !== place.name)
      await updateGroup(newGroup)
    }

  }, [currentGroup, updateGroup, place])

  return (
    <Box
      sx={theme => ({
        bgcolor: theme.palette.grey[theme.palette.mode === "dark" ? 800 : 100],
        borderRadius: "70px",
        display: 'flex',
        alignItems: 'center',
        p: "4px 12px"
      })}
    >
      {flag &&
        <IconContainer>
          <img
            src={flag}
            alt="flag"
            style={{ maxWidth: 12, maxHeight: 12 }}
          />
        </IconContainer>
      }
      <SearchedText
        fontWeight={600}
        fontSize="0.875rem"
        sx={theme => ({
          color: theme.palette.getContrastText(theme.palette.grey[theme.palette.mode === "dark" ? 800 : 100])
        })}
        text={title}
        search={search}
      />
      <Tooltip title="Remove from group">
        <IconContainer
          sx={{
            cursor: 'pointer'
          }}
          onClick={handleDelete}
        >
          <CloseIcon isGrey />
        </IconContainer>
      </Tooltip>
    </Box>
  );
}

export default PlaceItem;
