import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useUser } from '../../../contexts/UserContext'
import { DBUser } from '../../../types/Models'
import _ from 'lodash'

interface Props {
  open: boolean
  onClose: () => void
  boxUsers: DBUser[]
  boxPlaces: GroupPlace[]
  boxPeople: GroupPerson[]
}

const AddPeopleDialog = ({ open, onClose, boxUsers, boxPlaces, boxPeople }: Props) => {
  const { currentGroup, updateGroup } = useUser()
  const [selectedOption, setSelectedOption] = useState<string>("")

  const options = useMemo(() => {
    let result: GroupPlace[] = [...boxPlaces]

    boxUsers.forEach(u => {
      if (u.customProfile?.location) {
        const loc = u.customProfile?.location
        result.push({
          name: loc.cityName,
          timezone: loc.timezone,
          country_long_name: loc.countryLongName,
          country_short_name: loc.countryShortName
        })
      }
      else if (u.userIPLocationInfo) {
        result.push({
          name: u.userIPLocationInfo.time_zone.name,
          timezone: u.userIPLocationInfo.time_zone.name
        })
      }
    })

    boxPeople.forEach(person => {
      result.push({ ...person.place })
    })

    return _.uniqBy(result, r => r.name)
    // return _.uniqBy(result, r => r.timezone)
  }, [boxUsers, boxPlaces, boxPeople])

  useEffect(() => {
    if (selectedOption === "" && options.length > 1)
      setSelectedOption(options[0].name)
  }, [selectedOption, options])


  const save = useCallback((name: string, placeName?: string) => {
    if (!currentGroup)
      return

    if (options.length > 1 && !placeName)
      return

    const place = options.length === 1 ? options[0] : options.find(o => o.name === placeName)

    if (!place)
      return

    let newGroup = { ...currentGroup }

    newGroup.people = newGroup.people || []

    newGroup.people.push({
      id: new Date().getTime().toString(),
      name,
      place
    })

    updateGroup(newGroup)

    onClose()
  }, [currentGroup, options, onClose, updateGroup])

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      disableRestoreFocus
      open={open}
      onClose={onClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault()
          const formData = new FormData(event.currentTarget)
          var name = formData.get("name")?.toString()
          var place = formData.get("place")?.toString()
          console.log(name, place)
          if (name)
            save(name, place)
        },
      }}
    >
      <DialogTitle>
        Add People
      </DialogTitle>
      <DialogContent>
        <DialogContentText gutterBottom>
          Add a colleague or a client to this box
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          name="name"
          label="John Deo, Accounting firm, etc."
          fullWidth
          variant='standard'
          sx={{ mb: 8 }}
        />
        {options.length > 1 &&
          <FormControl fullWidth variant="standard">
            <InputLabel>City or Timezone</InputLabel>
            <Select
              name="place"
              required
              value={selectedOption}
              onChange={e => setSelectedOption(e.target.value)}
            >
              {options.map((place, i) => (

                <MenuItem key={i} value={place.name}>{place.name}{place.country_long_name ? `, ${place.country_long_name}` : ''}</MenuItem>
              ))}
            </Select>
          </FormControl>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit">Save</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddPeopleDialog