import { User } from "firebase/auth";
import fb from "../firebase";
import { helpers } from "../helpers";
import { AppAlert, DBUser, Space } from "../types/Models";

const createUserAndSpace = async (dbUser: DBUser, space: Space) => {
  const userIPLocationInfo = await helpers.user.getIPLocationInfo()
  if (userIPLocationInfo)
    dbUser.userIPLocationInfo = userIPLocationInfo

  const createdSpace = await fb.db.space.create(space);

  if (!createdSpace) {
    return false;
  }

  await fb.db.user.create(dbUser);

  return true
}

const onAuthStateChanges = async (
  user: User | null,
  showAlert: (alert: AppAlert) => void,
) => {
  console.log("onAuthStateChanged", user)

  if (!user || !user.email)
    return null

  const userEmailParts = user.email.toLowerCase().split("@");
  if (userEmailParts.length !== 2) {
    console.error("Invalid email format:", user.email);
    return null;
  }
  const userId = userEmailParts[0];
  const spaceId = userEmailParts[1];
  let spaceType: SpaceType = "google-workspace";

  if (user.providerData.length) {
    const providerId = user.providerData[0].providerId
    if (providerId === "password")
      spaceType = "company-domain"
  }

  if (spaceType === "google-workspace" && spaceId === "gmail.com") {
    showAlert({
      color: "error",
      message: "Access Denied: gmail.com domain is not allowed."
    })
    return null
  }

  //get existing space and user
  let space = await fb.db.space.get(spaceId)
  let dbUser: DBUser | null = null

  if (space) {
    dbUser = await fb.db.user.get(user.email)
  }

  //create the space if doesn't exists
  if (!space) {
    space = await fb.db.space.create({
      createdTime: fb.db.timestamp.now(),
      name: spaceId,
      id: spaceId,
      type: spaceType
    })
  }

  const userIPLocationInfo = await helpers.user.getIPLocationInfo();
  console.log("userIPLocationInfo", userIPLocationInfo);

  //create the user if doesn't exists
  if (!dbUser) {
    await fb.db.user.create({
      createdTime: fb.db.timestamp.now(),
      email: user.email,
      email_verified: true,
      family_name: '',
      given_name: userId,
      hd: spaceId,
      id: userId,
      locale: "en",
      name: userId,
      picture: '',
      spaceId,
      sub: '',
      userIPLocationInfo: userIPLocationInfo || undefined
    })
  }
  //if the user exists in the database
  //update user ip location
  else {
    if (userIPLocationInfo) {
      console.log("location check", dbUser.userIPLocationInfo?.city, userIPLocationInfo.city)

      if (dbUser.userIPLocationInfo && dbUser.userIPLocationInfo.city !== userIPLocationInfo.city && dbUser.customProfile?.location) {
        showAlert({
          color: 'info',
          message: "Your main location has been changed since the last time, just a quick reminder to update your custom location.",
          permanent: true
        })
      }

      //TODO: check if the update is needed
      fb.db.user.updateIPLocationInfo(user.email, userIPLocationInfo)
    }
  }

  return user
}

const userContextHelper = {
  createUserAndSpace,
  onAuthStateChanges,
}

export default userContextHelper