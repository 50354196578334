
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useApp } from '../../../contexts/AppContext';
import moment from 'moment';

interface Props {
  time: Date
}

const ShowTime: React.FC<Props> = ({ time }) => {
  const { timeFormat } = useApp()

  if (timeFormat === 24)
    return (
      <Typography
        fontSize={40}
        fontWeight={700}
        lineHeight="30px"
      >{moment(time).format("HH:mm")}</Typography>
    );

  return (
    <Box display="flex">
      <Typography
        fontSize={40}
        fontWeight={700}
        lineHeight="30px"
        marginRight={2}
      >{moment(time).format("hh:mm")}</Typography>
      <Typography
        fontSize={30}
        lineHeight="19px"
        fontWeight={400}
        sx={theme => ({ color: theme.palette.grey[500] })}
      >{moment(time).format("A")}</Typography>

    </Box>
  );
}

export default ShowTime;

