import { useMemo } from 'react';
// @mui
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider as MuiThemeProvider, ThemeOptions, Theme } from '@mui/material/styles';
import { useSettings } from '../contexts/SettingsContext';

type Props = {
  children: React.ReactNode;
};

export const PRETTY_SCROLL = (theme: Theme, disabled: boolean) => ({
  overflowY: disabled ? "hidden" : 'overlay',
  '&::-webkit-scrollbar': {
    width: '10px',
    backgroundColor: theme.palette.grey[theme.palette.mode === "dark" ? 800 : 100],
  },
  '&::-webkit-scrollbar-track': {
    // boxShadow: `inset 0 0 6px ${theme.palette.grey[theme.palette.mode === "dark" ? 800 : 100]}`
    position: "absolute",
    right: "-3rem",
    top: "-50rem",
    background: "transparent"
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[500],
    borderRadius: '5px'
  }
})

export default function ThemeProvider({ children }: Props) {
  const { mode } = useSettings()

  const baseOption = useMemo(() => ({
    typography: {
      fontFamily: "'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    },
    spacing: 4,
    shape: { borderRadius: 8 },
    palette: {
      mode,
      primary: {
        main: mode === "dark" ? "#fff" : "#000"
      },
      secondary: {
        main: mode === "dark" ? "#134fa3" : "#3a88f4"
      }
    }
  } as ThemeOptions), [mode]);

  const theme = createTheme(baseOption as ThemeOptions);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}