import React from 'react'
import { styled } from '@mui/material/styles';

const PersonIconSVG = (props: any) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4m0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4"></path>
  </svg>
)

const PersonIcon = styled(PersonIconSVG)(({ theme }) => {
  return {
    '& path': {
      fill: theme.palette.grey[theme.palette.mode === "dark" ? 200 : 800]
    }
  }
})

export default PersonIcon