import React from 'react';

//@mui
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import SearchIcon from '../../Icons/SearchIcon';
import { useApp } from '../../../contexts/AppContext';

interface Props {
  fullWidth?: boolean
  onEnter?: () => void
}

function SearchBox({ fullWidth, onEnter }: Props) {
  const { search, setSearch } = useApp()

  return (
    <TextField
      variant='outlined'
      placeholder='Search...'
      fullWidth={fullWidth}
      value={search}
      onChange={e => setSearch(e.target.value)}
      onKeyDown={!onEnter ? undefined : e => {
        if (e.key === "Enter") {
          onEnter()
          e.preventDefault()
        } 
        else if (e.key === "Escape") {
          setSearch('')
          e.preventDefault()
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
        sx: theme => ({
          backgroundColor: theme.palette.background.default,
          borderRadius: 16,
          width: fullWidth ? "100%" : theme.spacing(57),
          px: 3,
          py: 2,
          '& input': {
            padding: 0,
            fontSize: "0.875rem"
          },
          '& fieldset': {
            border: 'none',
          }
        })
      }}
    />
  );
}

export default SearchBox;