import React from 'react';

//@mui
import { Avatar, Box, Typography } from '@mui/material'
import { OptionType } from './SearchNewGroupItem'
import { helpers } from '../../../../helpers';

interface Props {
  props: React.HTMLAttributes<HTMLLIElement>
  option: OptionType
  currentUserTime: Date
}

const FONT_SIZE = "0.875rem"

function RenderOption({ props, option, currentUserTime }: Props) {
  if (option.type === "Users")
    return (
      <Box
        component="li"
        sx={theme => ({
          display: 'flex',
          alignItems: 'center',
          borderBottom: `1px solid ${theme.palette.grey[theme.palette.mode === "dark" ? 800 : 200]}`
        })}
        {...props}
      >
        <Avatar sx={{ mr: 2 }} src={option.data.picture} />
        <Typography fontSize={FONT_SIZE}>{helpers.user.getName(option.data)}</Typography>
      </Box>
    )

  if (option.type === "Cities")
    return (
      <Box component="li" {...props}>
        <Typography fontSize={FONT_SIZE}>{option.label}</Typography>
      </Box>
    )

  return (
    <Box component="li" {...props}>
      <Typography fontSize={FONT_SIZE}>{`${option.label} (${helpers.getUTCOffsetTitle(helpers.getTimezoneOffset(option.label, currentUserTime))})`}</Typography>
    </Box>
  )
}

export default RenderOption