import React, { createContext, useCallback, useContext, useEffect, useState } from "react";

type ThemeMode = "dark" | "light"

interface SettingsContextType {
  mode: ThemeMode
  setThemeMode: (mode: ThemeMode) => void
}

const SettingsContext = createContext<SettingsContextType | undefined>(undefined);

const getThemeMode: () => ThemeMode = () => {
  const storedValue = localStorage.getItem("themeMode")

  if (storedValue && (["light", "dark"].includes(storedValue)))
    return storedValue as ThemeMode

  return window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light"
}

export const SettingsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [mode, setMode] = useState(getThemeMode())

  const setThemeMode = useCallback((mode: ThemeMode) => {
    localStorage.setItem("themeMode", mode)
    setMode(mode)
  }, [])

  useEffect(() => {
    const handleChange = (event: MediaQueryListEvent) => {
      setMode(event.matches ? "dark" : "light")
    }

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', handleChange);

    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', handleChange);
    }
  }, [])

  return (
    <SettingsContext.Provider value={{
      mode,
      setThemeMode
    }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }
  return context;
};
