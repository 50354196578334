import React, { useState } from 'react';
import LoginLayout from './LoginLayout';
import LoginOptions from './LoginOptions';
import { useApp } from '../../contexts/AppContext';
import { Button, Typography } from '@mui/material';
import ArrowRightIcon from '../Icons/ArrowRightIcon';

function Login() {
  const { smallScreen } = useApp()
  const [submittedEmail, setSubmittedEmail] = useState('')


  return (
    <LoginLayout>

      {!submittedEmail &&
        <LoginOptions
          onEmailSent={setSubmittedEmail}
        />
      }

      {submittedEmail &&
        <>
          <Typography
            fontWeight={700}
            fontSize="2rem"
            textAlign="center"
            gutterBottom
            sx={{
              maxWidth: smallScreen ? "330px" : "420px"
            }}
          >
            Verify your email address
          </Typography>
          <p
            style={{
              textAlign: "center",
              marginBottom: 40,
              maxWidth: smallScreen ? "330px" : "420px"
            }}
          >
            We have sent a verification link to your email <b>{submittedEmail}</b>.
            Click on the link to complete the verification process. You might need to check your spam folder.
          </p>
          <Button
            variant="outlined"
            onClick={() => setSubmittedEmail("")}
            size="large"
            sx={theme => ({
              textTransform: "none",
              fontWeight: 600,
              lineHeight: 1.5
            })}
            endIcon={<ArrowRightIcon />}
          >
            Return to login
          </Button>
        </>
      }

    </LoginLayout>
  )
}

export default Login;
