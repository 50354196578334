import React from 'react'
import { styled } from '@mui/material/styles';

const EmailIconSVG = (props: any) => (
  <svg viewBox="0 0 24 24" width="16px" height="16px" fill="none" {...props}>
    <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2zm-2 0-8 5-8-5zm0 12H4V8l8 5 8-5z"></path>
  </svg>
)

const EmailIcon = styled(EmailIconSVG)(({ theme }) => {
  return {
    '& path': {
      fill: theme.palette.mode === "dark" ? "white" : "black"
    },
  }
})

export default EmailIcon