import { useMemo } from 'react';
import Header from './Header/Header';
import { Box } from '@mui/material';
import { useApp } from '../../contexts/AppContext';
import MainView from './MainView';
import GroupTitle from './Header/GroupTitle';
import TimeZoneBoxSlider from './TimeZoneBox/TimeZoneBoxSlider';
import { useUser } from '../../contexts/UserContext';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import GroupView from './GroupView/GroupView';
import SearchNewGroupItem from './GroupView/SearchNewGroupItem/SearchNewGroupItem';

const MainScreen = () => {
  const { smallScreen, appFilter } = useApp()
  const { currentGroup, filteredDBUsers } = useUser()
  const padding = smallScreen ? "16px" : "32px"

  const showGroupSearch = useMemo(() => appFilter.mode === "groups" && (filteredDBUsers.length > 0 || (currentGroup?.places || []).length > 0), [appFilter.mode, currentGroup?.places, filteredDBUsers.length])

  return (
    <Box
      sx={theme => ({
        px: 0,
        pt: padding,
        height: ['100dvh', '100vh'],
        bgcolor: theme.palette.grey[theme.palette.mode === "dark" ? 900 : 200],
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      })}
    >
      <Box sx={{ px: padding, pb: 4 }}>
        <Header />

        {smallScreen && <GroupTitle />}

        <TimeZoneBoxSlider />

        {showGroupSearch &&
          <SearchNewGroupItem />
        }

      </Box>

      {/* Content */}
      <SimpleBar
        autoHide={smallScreen}
        style={{
          flexGrow: 1,
          minHeight: 0,
          paddingLeft: padding,
          paddingRight: padding,
          paddingBottom: padding,
          // ...PRETTY_SCROLL(theme, menuIsOpen)
        }}
      >
        {appFilter.mode !== "groups" &&
          <MainView />
        }
        {appFilter.mode === "groups" &&
          <GroupView />
        }
      </SimpleBar>
    </Box>
  )
}

export default MainScreen;
