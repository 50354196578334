import React from 'react';
import { useUser } from '../contexts/UserContext';
import Login from './Login/Login';
import MainScreen from './MainScreen/MainScreen';
import LoadingScreen from './LoadingScreen';
import { useApp } from '../contexts/AppContext';
import EditProfile from './MainScreen/EditProfile/EditProfile';

function App() {
  const { currentDBUser, initializing, loadingUsers } = useUser()
  const { editProfileIsOpen } = useApp()

  if (initializing || loadingUsers)
    return <LoadingScreen />

  if (currentDBUser === null)
    return <Login />

  if (editProfileIsOpen)
    return <EditProfile />

  return <MainScreen />
}

export default App;
