import React, { useMemo } from 'react';

//@mui
import { IconButtonProps, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';

interface Props extends IconButtonProps {
  revert?: boolean
}

function CircleIconButton({ children, revert, ...rest }: Props) {
  const theme = useTheme()

  const color = useMemo(() => {
    if (revert)
      return theme.palette.mode === "dark" ? theme.palette.common.black : theme.palette.common.white

    return theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black
  }, [theme, revert])

  return (
    <IconButton
      {...rest}
      sx={theme => ({
        backgroundColor: revert ? (theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black) : theme.palette.background.default,
        p: theme.spacing(1.5),
        width: theme.spacing(9),
        height: theme.spacing(9),
        '& path': {
          stroke: color,
        }
      })}
    >
      {children}
    </IconButton>
  );
}

export default CircleIconButton;
